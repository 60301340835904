import { ColorPropsEnum } from '../../../../enum/color.enum';
import { OrderForList } from '../../../../models/order';
import moment from '../../../../moment-config.ts';
import {
  BubblePicto,
  Button,
  Carousel,
  Chips,
  DropdownMenu,
  IconButton,
  Text,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import { forwardRef, LegacyRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getBackgroundColor, isOrderChairsideCompliant } from '../../../../utils/orders.utils';
import styles from './order-card.module.scss';
import { deleteOrderAction } from './menu-entry.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import DeleteOrderModal from './delete-order-modal/DeleteOrderModal.tsx';
import { WorkflowStepEnum } from '../../../../enum/workflow-step.ts';
import {
  useLazyGetOneManufacturingOrderQuery,
  useLazyGetProductionFilesQuery
} from '../../../../services/manufacturing-orders-api.services.tsx';
import { useFiles } from '../../../../hooks/useFiles.tsx';

type Props = {
  order: OrderForList;
  handleDeleteOrderCard: (orderNumber: string) => void;
};

const OrderCard = forwardRef(
  ({ order, handleDeleteOrderCard }: Props, ref: LegacyRef<HTMLElement> | undefined) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isDeleteOrderModalOpened, setIsDeleteOrderModalOpened] = useState<boolean>(false);

    const [getProductionFiles, { isError: isErrorGetProductionZipFile }] =
      useLazyGetProductionFilesQuery();
    const [getOneManufacturingOrder] = useLazyGetOneManufacturingOrderQuery();

    const mode = isOrderChairsideCompliant(order) ? 'one' : 'inlab';

    const { zipProductionFiles } = useFiles();

    const handleDownload = async () => {
      if (!order) return;
      dispatch(
        feedbackActions.setToast({
          message: t('downloadProductionFiles.pending', { ns: 'treatments' }),
          type: ToastType.INFO
        })
      );
      const { data: manufacturingOrder } = await getOneManufacturingOrder(order.orderNumber);
      const { data: productionFiles } = await getProductionFiles({
        orderNumber: order.orderNumber,
        patientReference: order.patientReference
      });
      await zipProductionFiles(manufacturingOrder!, productionFiles!);
    };

    const handleDeleteOrder = async () => {
      setIsDeleteOrderModalOpened(true);
    };

    // If file not found
    useEffect(() => {
      if (isErrorGetProductionZipFile) {
        dispatch(
          feedbackActions.setToast({
            message: t('noProductionFiles', { ns: 'treatments' }),
            type: ToastType.DANGER
          })
        );
      }
    }, [isErrorGetProductionZipFile]);

    const availableActionKebabMenu = [[deleteOrderAction(order, handleDeleteOrder)]];

    const kebabMenu = (
      <DropdownMenu
        data-cy={`treatments-card-${order.id}-menu`}
        renderTargetButton={({ active }: { active: boolean }) => (
          <IconButton
            faIconClass="ellipsis-vertical"
            isActive={active}
            radius="full"
            color={mode === 'one' ? ColorPropsEnum.PRIMARY : ColorPropsEnum.WHITE}
          />
        )}
        data={availableActionKebabMenu}
      />
    );

    const onDeleteSuccess = () => {
      handleDeleteOrderCard(order.orderNumber);
      setIsDeleteOrderModalOpened(false);
    };

    const onCloseOrClickOutside = () => {
      setIsDeleteOrderModalOpened(false);
    };

    return (
      <article className={styles['order-card']} ref={ref} data-cy="treatments-card">
        <DeleteOrderModal
          isOpened={isDeleteOrderModalOpened}
          order={order}
          onDeleteSuccess={onDeleteSuccess}
          onCloseOrClickOutside={onCloseOrClickOutside}
        />
        <header
          className={[styles['order-card__header'], styles[`order-card__header--${mode}`]].join(
            ' '
          )}>
          <BubblePicto
            data-cy="treatments-card-picto"
            count={order.items?.length <= 1 ? undefined : order.items.length}
            url={order.items?.length > 0 ? order.items[0].product.imageUrl : undefined}
            backgroundColor={getBackgroundColor(order)}
            color={
              order.items?.length > 0
                ? ColorPropsEnum[
                    `FAMILY_${order.items[0].product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                  ]
                : undefined
            }
          />
          <div>
            <Tooltip>
              <TooltipContent>{order?.patientReference}</TooltipContent>
              <Text
                data-cy={`treatments-card-${order.id}-ref`}
                type="title"
                size="xs"
                label={order?.patientReference}
                bold={true}
                color={mode === 'one' ? ColorPropsEnum.PRIMARY : ColorPropsEnum.WHITE}
                className={styles['order-card__header__title']}
              />
            </Tooltip>
            <div className={styles['order-card__header__subtitle']}>
              <Text
                data-cy={`treatments-card-${order.id}-date`}
                size="s"
                label={moment(order.creationDate).format('MM/DD/YY')}
                color={mode === 'one' ? ColorPropsEnum.GREY : ColorPropsEnum.WHITE}
              />
            </div>
          </div>
          <div className={styles['order-card__header__kebab-menu']}>{kebabMenu}</div>
        </header>
        <div className={styles['order-card__section']}>
          <div className={styles['order-card__section__chips']}>
            {order?.productsEn && order?.productsEn?.length > 0 && (
              <Chips
                data-cy={`treatments-card-${order.id}-product`}
                firstLabel={order?.productsEn[0]}
                secondLabel={
                  order?.teethPositions && order?.teethPositions?.length > 0
                    ? order?.teethPositions[0].toString()
                    : '-'
                }
                color={ColorPropsEnum.SECONDARY}
              />
            )}
            {order.manufacturingProcesses && (
              <Chips
                data-cy={`treatments-card-${order.id}-manufacturing-processes`}
                firstLabel={t(`manufacturingProcess.${order.manufacturingProcesses[0]}`, {
                  ns: 'component'
                })}
                color={ColorPropsEnum.WHITE}
              />
            )}

            {order?.shades && order?.shades?.length > 0 && (
              <Chips
                data-cy={`treatments-card-${order.id}-shade`}
                firstLabel={order.shades[0]}
                color={order.shades[0].toString().toLowerCase()}
              />
            )}
          </div>
          {order.thumbnailUrls?.length && (
            <Carousel
              urls={order.thumbnailUrls}
              imageClassName={styles['order-card__section__carousel']}
            />
          )}

          {order.currentStep === WorkflowStepEnum.DELIVERED && (
            <div className={styles['order-card__section__status']}>
              <Tooltip>
                <TooltipContent>
                  {t('downloadProductionFiles.tooltip', { ns: 'treatments' })}
                </TooltipContent>
                <Button
                  data-cy="treatment-download"
                  label={t('downloadProductionFiles.action', { ns: 'treatments' })}
                  variant={ColorPropsEnum.PRIMARY}
                  size={'s'}
                  isDisabled={order.currentStep !== WorkflowStepEnum.DELIVERED}
                  onClick={handleDownload}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </article>
    );
  }
);

OrderCard.displayName = 'OrderCard';

export default OrderCard;
