import { Text } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';
import styles from './tips.module.scss';
import { useTranslation } from 'react-i18next';

export type ShortcutTip = {
  fontawesomeClassImage: string;
  key?: string;
  label: string;
};

type Props = {
  videoUrl?: string;
  title: string;
  description: string;
  shortcuts?: ShortcutTip[];
};

const TipCard = ({ videoUrl, title, description, shortcuts }: Props) => {
  const { t } = useTranslation(['tip']);

  return (
    <>
      <div className={styles['tips__box__tip__video']}>
        <video
          key={videoUrl}
          controls
          autoPlay
          loop
          className={styles['tips__box__tip__video__player']}>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
      <div className={styles['tips__box__tip__content']}>
        <Text label={t('tip')} className={styles['tips__box__tip__content__text']} size="s" />
        <Text
          label={title}
          className={styles['tips__box__tip__content__title']}
          color={ColorPropsEnum.PRIMARY}
        />
        <Text label={description} className={styles['tips__box__tip__content__text']} size="s" />

        {shortcuts?.map((shortcut, index) => (
          <div key={index} className={styles['tips__box__tip__content__example']}>
            <div className={styles['tips__box__tip__content__example__button']}>
              <Text
                label={`${shortcut.key}`}
                className={styles['tips__box__tip__content__text--key']}
                size="s"
              />
              <Text label={` +`} className={styles['tips__box__tip__content__text']} size="s" />
              <i className={shortcut.fontawesomeClassImage}></i>
            </div>

            <Text
              label={shortcut.label}
              className={styles['tips__box__tip__content__text']}
              size="s"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default TipCard;
