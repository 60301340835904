import { CellProps } from '@inovua/reactdatagrid-community/types';
import { Text, DropdownMenu, IconButton } from '@platform-storybook/circlestorybook';
import { getColumnOptions } from '../features/datagrid/datagrid.utils.tsx';
import { useTranslation } from 'react-i18next';

export const useDatagridColumns = () => {
  const { t } = useTranslation(['profile', 'component']);

  return {
    colMachineName: {
      ...getColumnOptions('machineName', t('datagrid.columns.machine', { ns: 'profile' }), 3),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left'
        }
      }),
      render: ({ value }: CellProps) => <Text label={value} />
    },

    colBrandReference: {
      ...getColumnOptions(
        'brandReference',
        t('datagrid.columns.brandReference', { ns: 'profile' }),
        3
      ),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left'
        }
      }),
      render: ({ value }: CellProps) => <Text label={value} />
    },

    colToolDiameter: {
      ...getColumnOptions('toolDiameter', t('datagrid.columns.toolDiameter', { ns: 'profile' }), 2),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left'
        }
      }),
      render: ({ value }: CellProps) => <Text label={value} />
    },

    colReinforcementThickness: {
      ...getColumnOptions(
        'reinforcementThickness',
        t('datagrid.columns.reinforcementThickness', { ns: 'profile' }),
        2
      ),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left'
        }
      }),
      render: ({ value }: CellProps) => <Text label={value} />
    },

    colMinimalCrownThickness: {
      ...getColumnOptions(
        'minimalCrownThickness',
        t('datagrid.columns.minimalCrownThickness', { ns: 'profile' }),
        2
      ),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left'
        }
      }),
      render: ({ value }: CellProps) => <Text label={value} />
    },

    colActions: (onDeleteAction: (configId: number, machineName: string) => void) => {
      return {
        ...getColumnOptions('menu', '', 1, false),
        showColumnMenuTool: false,
        minWidth: 60,
        render: ({ data }: CellProps) => {
          // Display kebab menu only on parent row
          return data.materialBrands && data.materialBrands.length > 0 ? (
            <DropdownMenu
              data-cy={`dropdown-menu--${data.id}`}
              renderTargetButton={({ active }: { active: boolean }) => (
                <IconButton
                  data-cy="datagrid-menu"
                  faIconClass="ellipsis-vertical"
                  isActive={active}
                  radius="full"
                />
              )}
              data={[
                [
                  {
                    label: t('action.delete', { ns: 'common' }),
                    type: 'button',
                    onClick: async () => {
                      onDeleteAction(data.id, data.machineName);
                    }
                  }
                ]
              ]}
            />
          ) : null;
        }
      };
    }
  };
};
