import i18next from 'i18next';
import { ErrorCode } from '../enum/error';
import { Country, DentistRole } from '../enum/user';
import { forceRefreshToken } from '../services/firebase.services.tsx';
import { Dispatch } from 'react';
import { feedbackActions } from '../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../enum/feedback.ts';
import {
  OrderItem,
  OrderItemLight,
  OrderItemForCreation,
  OrderItemComponentForCreation
} from '../models/order.tsx';
import {
  PositionKey,
  ComponentType,
  ComponentTypeProperties,
  ItemComponentProperties
} from '../enum/component.ts';
import { authActions } from '../store/auth/auth.reducers.tsx';
import { EstablishmentType } from '../enum/establishment.ts';

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('error.generic', { ns: 'common' });
  if ('data' in error) {
    if (typeof error.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(error.data.error)) {
        message = i18next.t(error.data.error, { ns: 'error' });
      } else if (error.status !== 500 && error?.data?.message) {
        message = error.data.message;
      }
    }
  }

  return message;
};

export const publicImagesUrl = 'https://storage.googleapis.com/public-circle-dental/images/';
export const publicDocumentsUrl = 'https://storage.googleapis.com/public-circle-dental/';
export const publicOneNewsUrlFr =
  'https://www.circle.one/actualite/circle-sera-present-a-lids-2025';
export const publicOneNewsUrlEn = 'https://www.circle.one/en/news/circle-is-heading-to-ids-2025';
export const publicManualUrl = 'https://doc.one.circle.dental';

export const defaultProductIconUrl = `${publicImagesUrl}gear-solid.svg`;

export const capitalizeFirstLetter = (string: string) => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const countries = [
  Object.values(Country).map((country) => {
    return {
      label: i18next.t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
      value: country
    };
  })
];

export const viteMode = (): string => {
  let mode = '';
  if (import.meta.env.MODE === 'development' && import.meta.env.VITE_GOOGLE_CLOUD_DEV) {
    mode = 'dev';
  } else if (import.meta.env.MODE === 'development') {
    mode = 'local';
  }
  return mode;
};

export const isOrdersApi = (urlStartPart: string): boolean => {
  return ['orders', 'common-types', 'components', 'products', 'labs'].includes(urlStartPart);
};

export const isUsersApi = (urlStartPart: string): boolean => {
  return ['users', 'establishments', 'machines'].includes(urlStartPart);
};

export const isManuApi = (urlStartPart: string): boolean => {
  return ['manufacturing'].includes(urlStartPart);
};

export const checkTokenValidity = (dispatch: Dispatch<any>) => {
  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    if (err?.message.includes('auth/user-token-expired')) {
      dispatch(
        feedbackActions.setToast({
          message: i18next.t('auth/user-token-expired', { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
    } else {
      dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
    }
  });
};

export const getLocalizedProperty = (property: string): string => {
  switch (property) {
    case 'name':
    case 'label':
      // This adds the locale after the filter name (for example, label -> labelFr or labelEn)
      return `label${capitalizeFirstLetter(i18next.language)}`;
    case 'productLabel':
      return `productLabel${capitalizeFirstLetter(i18next.language)}`;
    case 'products':
      return `products${capitalizeFirstLetter(i18next.language)}`;
    default:
      return property;
  }
};

/**
 * Maps an OrderItem or OrderItemLight object to an OrderItemForCreation object.
 *
 * @param {OrderItem | OrderItemLight} orderItem - The order item object to map.
 * @returns {OrderItemForCreation} - The mapped order item for creation object.
 */
export const mapItemToItemForCreation = (
  orderItem: OrderItem | OrderItemLight
): OrderItemForCreation => {
  return {
    product: {
      id: orderItem.product.id
    },
    tags: [],
    itemComponents: orderItem.itemComponents?.map((item) => {
      const itemToCreate: OrderItemComponentForCreation = {
        componentType: item.componentType,
        svgLayer: item?.svgLayer
      };
      if (item.material?.id) {
        itemToCreate.material = { id: item.material?.id };
      }
      if (item.shape?.id) {
        itemToCreate.shape = { id: item.shape.id };
      }
      if (item.shade?.id) {
        itemToCreate.shade = { id: item.shade.id };
      }
      if (item.teethPositions?.length) {
        itemToCreate.teethPositions = item.teethPositions;
      }
      if (item.brandReference?.id) {
        itemToCreate.brandReference = { id: item.brandReference.id };
      }
      if (item.angulation?.id) {
        itemToCreate.angulation = { id: item.angulation.id };
      }
      if (item.aspect?.id) {
        itemToCreate.aspect = { id: item.aspect.id };
      }
      if (item.implantAttachment) {
        itemToCreate.implantAttachment = item.implantAttachment;
      }
      if (item.injectionPositions) {
        itemToCreate.injectionPositions = item.injectionPositions;
      }
      if (item.stumpPositions?.length) {
        itemToCreate.stumpPositions = item.stumpPositions;
      }
      if (item.toothStratificationTechnique) {
        itemToCreate.toothStratificationTechnique = item.toothStratificationTechnique;
      }
      return itemToCreate;
    })
  };
};

/**
 * Check if the middle maxillary teeth are present in the current item.
 *
 * @param {Array<number>} positions - An array of tooth positions
 * @returns {boolean} - True if the middle maxillary teeth are present, false otherwise
 */
export const areMiddleMaxillaryTeethInCurrentItem = (positions: Array<number>): boolean => {
  return [11, 12, 13, 21, 22, 23].some((position) => positions.includes(position as PositionKey));
};

export const getMappings = (customizationKey: string) => {
  const mapping: {
    [key: string]: {
      componentType: ComponentType; //TOOTH FRAME
      componentTypeProperty: ComponentTypeProperties; // shades, materials
      itemComponentProperty: ItemComponentProperties; // shade, shape, material
    };
  } = {
    frameMaterial: {
      componentType: ComponentType.FRAME,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    gingivaAspect: {
      componentType: ComponentType.GINGIVA,
      componentTypeProperty: ComponentTypeProperties.ASPECTS,
      itemComponentProperty: ItemComponentProperties.ASPECT
    },
    gingivaShade: {
      componentType: ComponentType.GINGIVA,
      componentTypeProperty: ComponentTypeProperties.SHADES,
      itemComponentProperty: ItemComponentProperties.SHADE
    },
    gingivaMaterial: {
      componentType: ComponentType.GINGIVA,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    partialToothMaterial: {
      componentType: ComponentType.PARTIAL_TOOTH,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    teethMaterial: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.MATERIALS,
      itemComponentProperty: ItemComponentProperties.MATERIAL
    },
    teethShade: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.SHADES,
      itemComponentProperty: ItemComponentProperties.SHADE
    },
    partialToothShade: {
      componentType: ComponentType.PARTIAL_TOOTH,
      componentTypeProperty: ComponentTypeProperties.SHADES,
      itemComponentProperty: ItemComponentProperties.SHADE
    },
    teethShape: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.SHAPES,
      itemComponentProperty: ItemComponentProperties.SHAPE
    },
    toothStratificationTechnique: {
      componentType: ComponentType.TOOTH,
      componentTypeProperty: ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES,
      itemComponentProperty: ItemComponentProperties.TOOTH_STRATIFICATION_TECHNIQUE
    }
  };

  if (Object.keys(mapping).includes(customizationKey)) {
    return mapping[customizationKey];
  }
  return null;
};

export const saveUserStateForTests = (dispatch: Dispatch<object>) => {
  const isAuthorized = sessionStorage.getItem('isAuthorizedForTests') !== 'false';
  const roleValue = sessionStorage.getItem('roleForTests');
  const role =
    roleValue && Object.values(DentistRole).includes(roleValue as DentistRole)
      ? (roleValue as DentistRole)
      : DentistRole.DENTIST;
  dispatch(
    authActions.saveUserState({
      firebaseUser: '{"email": "dev-platform+cypress@circle.dental", "name": "cypress user"}',
      isAuthorized,
      role
    })
  );
};

export const isClinic = (establishmentType: string): boolean => {
  return establishmentType === EstablishmentType.CLINIC;
};

export const toKebabCase = (string: string) =>
  string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
