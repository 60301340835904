import { Family, ProductCategory } from '../../enum/product.enum';
import { Product } from '../../models/product';

enum EMPTY_CATEGORY {
  'NONE'
}

export type MappedProducts = {
  [key in Family]?: {
    [key in ProductCategory | EMPTY_CATEGORY]?: Product[];
  };
};

/**
 * Products manager class to create the families, categories, products lists for the current user for a specific lab.
 */
export default class ProductsManager {
  private _families: Family[] = [];
  private _categories: ProductCategory[] = [];
  private _rawProducts: Product[] = [];
  private _mappedProducts: MappedProducts | undefined = undefined;

  /**
   * Init all the constant with the values returned by Api calls.
   *
   * @param families
   * @param categories
   * @param rawProducts
   */
  init(families: Family[], categories: ProductCategory[], rawProducts: Product[]) {
    this._categories = categories;
    this._families = families;
    this._rawProducts = rawProducts;
    this._mappedProducts = this.computeMappedProducts();
  }

  /**
   * Get the product mapped. The object is contained family as key in the first level.
   * The second level is the category available for this family and if products have one.
   * Otherwise, the category is EMPTY_CATEGORY.NONE.
   * And at the last level, all the product for this category.
   */
  get mappedProducts() {
    return this._mappedProducts;
  }

  /**
   * Create the future mappedProducts object after the constant families, categories and products.
   * @private
   */
  private computeMappedProducts() {
    return this._families.reduce((acc: MappedProducts, family: Family) => {
      const productsForOneFamily: { [key in ProductCategory | EMPTY_CATEGORY]?: Product[] } = {};

      // ordering the products by labelFr
      const orderedProductByLabel = [...this._rawProducts].sort((a, b) =>
        (a.labelFr ?? '').localeCompare(b.labelFr ?? '')
      );

      const productsFilterByFamily = orderedProductByLabel?.filter(
        (product: Product) => product.family === family
      );

      this._categories.forEach((category: ProductCategory) => {
        const productsFilteredByCategory = productsFilterByFamily?.filter(
          (product) => product.category === category
        );
        if (productsFilteredByCategory.length > 0) {
          productsForOneFamily[category] = productsFilteredByCategory;
        }
      });

      const productsWithoutCategory = productsFilterByFamily?.filter(
        (product) => product.category === null
      );
      if (productsWithoutCategory.length > 0) {
        productsForOneFamily[EMPTY_CATEGORY.NONE] = productsWithoutCategory;
      }

      if (Object.keys(productsForOneFamily).length > 0) {
        acc[family] = productsForOneFamily;
      }

      return acc;
    }, {});
  }

  /**
   * Get the first family from the products map.
   */
  get firstFamilyKey(): Family | undefined {
    return this.getFirstFamilyKey();
  }

  /**
   * Calculate the first family from the products map.
   * @private
   */
  private getFirstFamilyKey(): Family | undefined {
    return this._mappedProducts && Object.keys(this._mappedProducts).length > 0
      ? (Object.keys(this._mappedProducts)[0] as Family)
      : undefined;
  }

  /**
   * Get the first category for the first family from the products map.
   */
  get firstCategoryKey() {
    return this.getFirstCategoryKey();
  }

  /**
   * Calculate the first category for the first family from the products map.
   * @private
   */
  private getFirstCategoryKey() {
    return this._mappedProducts &&
      this.firstFamilyKey &&
      Object.keys(this._mappedProducts[this.firstFamilyKey] as Product[])[0].length
      ? (Object.keys(this._mappedProducts[this.firstFamilyKey] as Product[])[0] as ProductCategory)
      : undefined;
  }

  /**
   * Calculate the first category from the products map.
   * @param familyKey
   */
  getFirstCategoryFromFamilyKey(familyKey: Family) {
    return this._mappedProducts && Object.keys(this._mappedProducts[familyKey] as Product[]).length
      ? Object.keys(this._mappedProducts[familyKey] as Product[])[0]
      : undefined;
  }
}
