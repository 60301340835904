import StreamFeature from './StreamFeature';
import { useEffect, useState } from 'react';
import { WorkflowStepEnum } from '../../../../enum/workflow-step.ts';
import { useNavigate } from 'react-router-dom';
import { useGetOneOrderQuery } from '../../../../services/orders-api.services.ts';
import { StreamingParams } from '../../../../models/streaming.tsx';
import { Loader } from '@platform-storybook/circlestorybook';
import styles from './design-step.module.scss';
import { useDeleteOrder } from '../../../../hooks/useDeleteOrder.tsx';
import { useGetOneManufacturingOrderQuery } from '../../../../services/manufacturing-orders-api.services.tsx';

type Props = {
  orderNumber: string;
  nextCallback: () => void;
};
const DesignStep = ({ orderNumber, nextCallback }: Props) => {
  const navigate = useNavigate();

  const [streamingParams, setStreamingParams] = useState<StreamingParams | undefined>(undefined);
  // at first, we query ms-orders and skip ms-manufacturing
  const [skipOrderQuery, setSkipOrderQuery] = useState<boolean>(false);
  const [skipManufacturingOrderQuery, setSkipManufacturingOrderQuery] = useState<boolean>(true);
  const [isStreamingClosing, setIsStreamingClosing] = useState<boolean>(false);

  const onDeleteSuccess = () => {
    navigate('/treatments');
  };

  const { deleteOrderHandler } = useDeleteOrder(onDeleteSuccess);

  // we query ms-order => until we reach validation step => then allow query on manufacturing order and skip order
  // when the streaming is closed, we query again ms-order to check the current step to know if we should quit the order or go to the next step
  const { data: order } = useGetOneOrderQuery(orderNumber, {
    pollingInterval: 2000,
    skip: skipOrderQuery
  });

  // we query ms-manufacturing-order to get the streaming parameters as soon as we have received validation step from ms-order
  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(orderNumber, {
    pollingInterval: 2000,
    skip: skipManufacturingOrderQuery
  });

  if (
    !isStreamingClosing &&
    order?.currentStep &&
    [WorkflowStepEnum.MODELING_DESIGN].includes(order.currentStep as WorkflowStepEnum)
  ) {
    setSkipOrderQuery(true);
    setSkipManufacturingOrderQuery(false);
  }

  useEffect(() => {
    if (manufacturingOrder?.streamingParameters) {
      setStreamingParams(JSON.parse(manufacturingOrder.streamingParameters));
      setSkipManufacturingOrderQuery(true);
    }
  }, [manufacturingOrder?.streamingParameters]);

  useEffect(() => {
    if (isStreamingClosing) {
      if (order?.currentStep === WorkflowStepEnum.DELIVERED) {
        setSkipOrderQuery(true);
        nextCallback();
      }

      if (order?.currentStep === WorkflowStepEnum.SUBMITTING) {
        // Later, when we exit the stream, we will have the choice of saving the command or deleting it
        setSkipOrderQuery(true);
        deleteOrderHandler(order.orderNumber, order.patient.reference);
      }
    }
  }, [order?.currentStep, isStreamingClosing]);

  const closeStreaming = (): void => {
    setSkipOrderQuery(false);
    setIsStreamingClosing(true);
  };

  return (
    <>
      {isStreamingClosing ? (
        <div className={styles['design-step__loader']}>
          <Loader className={styles['design-step__loader__content']} />
        </div>
      ) : (
        <StreamFeature streamingParams={streamingParams} onCloseStreaming={closeStreaming} />
      )}
    </>
  );
};

export default DesignStep;
