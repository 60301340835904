import { api } from './api';
import { Machine } from '../models/manufacturing-config.tsx';

export const machinesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMachines: builder.query<Machine[], void>({
      query: () => {
        return `/machines`;
      }
    })
  })
});

export const { useGetMachinesQuery } = machinesApi;
