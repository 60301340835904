import { MaterialEnum } from '../enum/component.ts';
import { Machine, MachineItemDatagrid, MachineMaterialInfo } from '../models/machine.tsx';
import { ManufacturingConfig } from '../models/manufacturing-config.tsx';

export const extractMachineMaterialInfo = (machines: Machine[]): MachineMaterialInfo[] => {
  return machines
    .filter((machine) => Array.isArray(machine.params) && machine.params.length > 0) // Ensure params exist
    .flatMap((machine) =>
      machine.params!.map((param) => ({
        id: param.id,
        machineName: machine.name,
        material: param.material as MaterialEnum,
        brandReference: param.brandReference
      }))
    );
};

export const mapManufacturingConfigsDatagrid = (
  ManufacturingConfig: ManufacturingConfig[]
): MachineItemDatagrid[] => {
  return ManufacturingConfig.filter(
    (manufacturingConfig) =>
      Array.isArray(manufacturingConfig.materialBrands) &&
      manufacturingConfig.materialBrands.length > 0
  ).map((manufacturingConfig) => {
    return {
      id: manufacturingConfig.id,
      machineName: manufacturingConfig.machine?.name,
      materialBrands: manufacturingConfig.materialBrands!.map((material) => ({
        id: material.id,
        brandReference: `${material.name} (${material.acronym})`,
        toolDiameter: manufacturingConfig.toolDiameter,
        reinforcementThickness: material.reinforcementThickness,
        minimalCrownThickness: material.minimalCrownThickness
      }))
    };
  });
};
