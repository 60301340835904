import { useAppSelector } from '../../../hooks/hooks.tsx';
import { connectedUserSelector } from '../../../store/auth/auth.selectors.tsx';
import { useTranslation } from 'react-i18next';
import { useGetConnectedUserQuery } from '../../../services/user.api.services.ts';
import styles from './profile-page.module.scss';
import { Divider, Loader } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import ModelingSection from './ModelingSection.tsx';
import EstablishmentSection from './EstablishmentSection.tsx';
import CustomizationSection from './CustomizationSection.tsx';
import MachineSection from './MachineSection.tsx';
import InfoSection from './InfoSection.tsx';

const ProfilePage = () => {
  const connectedUser = useAppSelector(connectedUserSelector);
  const { t } = useTranslation(['profile']);
  const { data: user, isLoading, error } = useGetConnectedUserQuery();

  return connectedUser?.email ? (
    <>
      <div className={styles['profile-page__content']}>
        {/*********   Info section    ********/}
        <InfoSection user={user} isLoading={isLoading} error={!!error} />

        <Divider
          color={ColorPropsEnum.GREY_300}
          className={styles['profile-page__content__divider']}
        />

        {/*********   Clinic section    ********/}

        <EstablishmentSection
          establishment={user?.clinic}
          title={t('detailDentist.clinic')}
          isLoading={isLoading}
          error={!!error}
        />

        <Divider
          color={ColorPropsEnum.GREY_300}
          className={styles['profile-page__content__divider']}
        />

        {/*********   Machines section    ********/}
        {<MachineSection user={user} isLoading={isLoading} />}

        <Divider
          color={ColorPropsEnum.GREY_300}
          className={styles['profile-page__content__divider']}
        />

        {/*********   Modeling Params section    ********/}
        <ModelingSection user={user} error={!!error} isLoading={isLoading} />

        {/*********   Customization section    ********/}
        <CustomizationSection user={user} error={!!error} isLoading={isLoading} />
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default ProfilePage;
