import styles from './navigation-drawer.module.scss';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEllipsisVertical, faGear } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { SyntheticEvent, useState } from 'react';
import { NavigationDrawerItems } from '../../models/navigation';
import { Chips } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';

library.add(faGear, faEllipsisVertical);

interface NavigationDrawerProps {
  envLabel: string;
  items: NavigationDrawerItems;
  zIndex: number;
}

export const NavigationDrawer = ({ envLabel, items, zIndex = 3 }: NavigationDrawerProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isHoverDisabled, setIsHoverDisabled] = useState<boolean>(false);
  const expand = (e: SyntheticEvent) => {
    const target = e.target as HTMLElement;
    // if hovering over the expand button prevent hover event
    if (
      target instanceof HTMLElement &&
      (target.className.split(' ').includes(styles['navigation-drawer__toggle-button']) ||
        target.className.split(' ').includes(styles['navigation-drawer__toggle-button__icon']))
    ) {
      return;
    }
    // else expand
    setIsOpened(true);
  };

  const collapse = () => {
    return setIsOpened(false);
  };

  const onToggleBtnClick = () => {
    // if menu has been opened with the toggle button, disable expanding/collapsing menu on hovering
    if (!isOpened) {
      setIsHoverDisabled(true);
    }
    // restore hovering when we collapse with the toggle button
    if (isOpened && isHoverDisabled) {
      setIsHoverDisabled(false);
    }

    setIsOpened(!isOpened);
  };

  const onMouseLeaveToggleBtn = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    // when mouse is hovering the area over the toggle button, and leaves towards the menu, force the menu to open
    const relatedTarget = e.relatedTarget as HTMLElement | null;
    if (
      relatedTarget instanceof HTMLElement &&
      relatedTarget.className.split(' ').includes(styles['navigation-drawer'])
    ) {
      setIsOpened(true);
    }
  };

  const itemsList = ['upper', 'lower'].map((section) => (
    <ul
      key={section}
      className={[
        styles['navigation-drawer__section'],
        styles[`navigation-drawer__section--${section}`]
      ].join(' ')}>
      {items[section as 'upper' | 'lower'].map((item, id) => (
        <li key={`${section}-${id}`}>
          {item.link && (
            <NavLink
              data-cy={item['dataCy'] || ''}
              className={({ isActive }) =>
                [
                  styles['navigation-drawer__section__item'],
                  styles['navigation-drawer__section__item--link'],
                  item.isDisabled && styles['navigation-drawer__section__item--disabled'],
                  !isOpened
                    ? styles['navigation-drawer__section__item--collapsed--link']
                    : styles['navigation-drawer__section__item--expanded--link'],
                  styles[isActive ? 'navigation-drawer__section__item--active' : '']
                ].join(' ')
              }
              to={item.link}
              onClick={(event) => {
                if (item.isDisabled) event.preventDefault();
              }}>
              {item.icon && (
                <div className={styles['navigation-drawer__section__item__icon']}>
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                </div>
              )}
              <span
                className={[
                  styles['navigation-drawer__section__item__text'],
                  !isOpened ? styles['navigation-drawer__section__item__text--collapsed'] : ''
                ].join(' ')}>
                {item.label}
              </span>
            </NavLink>
          )}
          {item.onClick && (
            <button
              className={[
                styles['navigation-drawer__section__item'],
                styles['navigation-drawer__section__item--button'],
                !isOpened
                  ? styles['navigation-drawer__section__item--collapsed--button']
                  : styles['navigation-drawer__section__item--expanded--button']
              ].join(' ')}
              disabled={item.isDisabled}
              onClick={() => item.onClick && item.onClick()}
              data-cy={item['dataCy'] || ''}>
              {item.icon && (
                <div className={styles['navigation-drawer__section__item__icon']}>
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                </div>
              )}
              <span
                className={[
                  styles['navigation-drawer__section__item__text'],
                  !isOpened && styles['navigation-drawer__section__item__text--collapsed']
                ].join(' ')}>
                {item.label}
              </span>
            </button>
          )}
        </li>
      ))}
    </ul>
  ));

  return (
    <nav>
      <div
        style={{ zIndex: zIndex }}
        className={[
          styles['navigation-drawer'],
          !isOpened ? styles['navigation-drawer--collapsed'] : styles['navigation-drawer--expanded']
        ].join(' ')}
        {...(isHoverDisabled ? {} : { onMouseEnter: expand })}
        {...(isHoverDisabled ? {} : { onMouseLeave: collapse })}>
        <a href="/" className={styles['navigation-drawer__head']}>
          <img src="/logo/logo-circle-purple.svg" alt="Circle logo" width="33" height="33" />
          {isOpened && (
            <img src="/logo/logo-circle-text.svg" alt="Circle logo text" width="93" height="33" />
          )}
        </a>
        {envLabel && <Chips firstLabel={envLabel} color={ColorPropsEnum.PRIMARY} />}
        {itemsList}
        <button
          style={{ zIndex: zIndex }}
          className={styles['navigation-drawer__toggle-button']}
          onMouseLeave={onMouseLeaveToggleBtn}
          onClick={onToggleBtnClick}
          data-cy={'navigation-drawer_trigger'}>
          <div
            className={[
              styles['navigation-drawer__toggle-button__icon'],
              !isOpened
                ? styles['navigation-drawer__toggle-button__icon--collapsed']
                : styles['navigation-drawer__toggle-button__icon--expanded']
            ].join(' ')}>
            <FontAwesomeIcon icon={faChevronRight} size="sm" />
          </div>
        </button>
      </div>
    </nav>
  );
};
