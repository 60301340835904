import { BubblePicto } from '@platform-storybook/circlestorybook';
import styles from './map-tools.module.scss';
import { ColorPropsEnum } from '../../../../../enum/color.enum.ts';
import { ToothShadeEnum } from '../../../../../enum/component.ts';
import { FullProduct } from '../../../../../models/product.tsx';
import { Shade } from '../../../../../models/common-types.tsx';
import { mapActions } from '../../../../../store/map/map.reducer.tsx';
import { useAppDispatch } from '../../../../../hooks/hooks.tsx';
import { isComponentTooth } from '../../../../../utils/orders.utils.ts';

type Props = {
  currentProduct?: FullProduct;
  selectedShade?: ToothShadeEnum;
};

const ShadesBubbles = ({ currentProduct, selectedShade }: Props) => {
  const dispatch = useAppDispatch();

  const toothComponent = currentProduct?.components?.find((component) =>
    isComponentTooth(component)
  );

  const onShadeBubbleClick = (shade: Shade): void => {
    dispatch(mapActions.setTeethShade(shade.code as ToothShadeEnum));
  };

  return toothComponent?.shades?.map((shade) => {
    const borderColor =
      shade.code === selectedShade ? ColorPropsEnum.PRIMARY : ColorPropsEnum.WHITE;

    return Object.values(ToothShadeEnum).includes(shade.code as ToothShadeEnum) ? (
      <div className={styles['map-tools__shade-button']} key={shade.id}>
        <BubblePicto
          isClickable
          isHoverable
          type="text"
          hoverBorderColor="primary"
          onClick={() => onShadeBubbleClick(shade)}
          size="medium"
          backgroundColor={shade.code?.toLowerCase()}
          color={borderColor}
          mainTitle={shade.code?.replace('_', '.')}
          mainTitleSize="s"
          data-cy={`shade-bubble-${shade.code?.toLowerCase()}`}
        />
      </div>
    ) : null;
  });
};

export default ShadesBubbles;
