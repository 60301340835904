import React, { useState } from 'react';
import styles from './profile-page.module.scss'; // Assure-toi que le fichier CSS existe
import { Button, IconButton, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { Establishment } from '../../../models/user.tsx';
import { isClinic } from '../../../utils/utils.tsx';
import { CommonDataValue } from '../../../enum/common.enum.ts';
import { Country } from '../../../enum/user.ts';
import EditClinicModal from './edit-clinic-modal/EditClinicModal.tsx';
import SkeletonList from '../../../features/skeleton-list/SkeletonList.tsx';

interface Props {
  error: boolean;
  isLoading: boolean;
  establishment: Establishment | undefined;
  title: string;
}

const EstablishmentSection: React.FC<Props> = ({ error, establishment, title, isLoading }) => {
  const { t } = useTranslation(['profile']);
  const [isOpenEditClinic, setIsOpenEditClinic] = useState<boolean>(false);

  return (
    <>
      <section className={styles['profile-page__content__section']}>
        <header className={styles['profile-page__content__section__header']}>
          <Text
            data-cy="clinic-title"
            className={styles['profile-page__content__section__header__title']}
            size="s"
            type="title"
            label={title}></Text>
          {establishment &&
            isClinic(establishment.type) &&
            establishment.address?.address !== CommonDataValue.EMPTY && (
              <IconButton
                data-cy="edit-clinic-btn"
                iconSize="sm"
                faIconClass="fa-pen-to-square"
                onClick={() => setIsOpenEditClinic(true)}
                radius="full"
              />
            )}
        </header>
        {isLoading ? (
          <SkeletonList heightItem="30px" widthItem="300px" count={3} />
        ) : (
          !error &&
          establishment &&
          Object.keys(establishment).length > 0 &&
          (establishment.address?.address === CommonDataValue.EMPTY ? (
            <Button
              data-cy="clinic-add"
              category="filled"
              iconLeft="fa-plus"
              label={t('detailDentist.addClinicButton')}
              onClick={() => {
                setIsOpenEditClinic(true);
              }}
            />
          ) : (
            <>
              <Text label={establishment.name}></Text>
              {establishment.address && (
                <>
                  <Text label={establishment.address.address}></Text>
                  {establishment.address.additionalAddress && (
                    <Text label={establishment.address.additionalAddress}></Text>
                  )}
                  <Text
                    label={
                      establishment.address?.zipCode +
                      ' ' +
                      establishment.address?.city +
                      ' ' +
                      (establishment.address?.region ?? '')
                    }></Text>
                  <Text
                    label={
                      Object.values(Country).includes(establishment.address?.country as Country)
                        ? t(`countries.${establishment.address?.country?.toLowerCase()}`, {
                            ns: 'common'
                          })
                        : ''
                    }></Text>
                </>
              )}
            </>
          ))
        )}
      </section>
      {/*  Edit clinic modal*/}
      {establishment && isClinic(establishment.type) && (
        <EditClinicModal
          isOpened={isOpenEditClinic}
          establishment={establishment}
          onClose={() => setIsOpenEditClinic(false)}
        />
      )}
    </>
  );
};

export default EstablishmentSection;
