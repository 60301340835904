import { Toast } from '@platform-storybook/circlestorybook';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks.tsx';
import { history } from '../../models/history.tsx';
import { useTranslation } from 'react-i18next';
import style from './private-app.module.scss';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { useEffect } from 'react';
import {
  faHeadset,
  faUser,
  faPowerOff,
  faHouse,
  faGrid2
} from '@fortawesome/pro-regular-svg-icons';
import { NavigationDrawer } from '../../features/navigation-drawer/NavigationDrawer.tsx';
import { authActions } from '../../store/auth/auth.reducers.tsx';
import PrivateAppHeader from '../../features/private-app-header/PrivateAppHeader.tsx';
import { useGetConnectedUserQuery } from '../../services/user.api.services.ts';
import { Language } from '../../enum/user.ts';
import i18n from '../../i18n.tsx';
import { viteMode } from '../../utils/utils.tsx';
import { resetAll } from '../../store';

type props = {
  displayNavigationDrawer: boolean;
  displayHeader: boolean;
};

const PrivateApp = ({ displayNavigationDrawer, displayHeader }: props) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  history.navigate = useNavigate();
  const { data: connectedUser } = useGetConnectedUserQuery();
  const toast = useAppSelector(toastSelector);
  const envLabel = viteMode();

  const contentMainMenu = {
    upper: [
      {
        label: t('link.home'),
        type: 'nav',
        link: '/home',
        icon: faHouse,
        dataCy: 'menu-home'
      },
      {
        label: t('link.myTreatments'),
        type: 'nav',
        link: '/treatments',
        icon: faGrid2,
        dataCy: 'menu-treatments'
      },
      {
        label: t('link.support'),
        type: 'nav',
        link: '/support',
        icon: faHeadset,
        dataCy: 'menu-support'
      }
    ],
    lower: [
      {
        label: t('link.myProfile'),
        type: 'nav',
        link: '/profile',
        isDisabled: false,
        icon: faUser,
        dataCy: 'menu-profile'
      },
      {
        label: t('link.logout'),
        type: 'button',
        onClick: () => {
          dispatch(resetAll());
          dispatch(authActions.logout());
        },
        icon: faPowerOff,
        dataCy: 'menu-logout'
      }
    ]
  };

  useEffect(() => {
    if (connectedUser) {
      const currentLanguage = connectedUser?.language === Language.fr_FR ? 'fr' : 'en';
      i18n.changeLanguage(currentLanguage);
    }
  }, [connectedUser]);

  return (
    <div className={style['private-app']} data-cy="privateApp">
      {toast && (
        <Toast
          data-cy={`toast-${toast.type}`}
          message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
          onClose={() => {
            dispatch(feedbackActions.resetToast());
          }}
          autoHideDuration={toast.type === 'danger' ? 8000 : 5000}
          type={toast.type}
        />
      )}
      {displayHeader && <PrivateAppHeader envLabel={envLabel} />}
      {displayNavigationDrawer && (
        <NavigationDrawer envLabel={envLabel} items={contentMainMenu} zIndex={12} />
      )}
      <main
        className={[
          style['private-app__main'],
          displayHeader && style['private-app__main--with-navigation-drawer'],
          displayNavigationDrawer && style['private-app__main--with-left-menu']
        ].join(' ')}>
        <Outlet />
      </main>
    </div>
  );
};
export default PrivateApp;
