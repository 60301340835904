import React, { useState } from 'react';
import styles from './profile-page.module.scss'; // Assure-toi que le fichier CSS existe
import { Divider, IconButton, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { UserInfos } from '../../../models/user.tsx';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import EditModelingParamModal from './edit-modeling-param-modal/EditModelingParamModal.tsx';
import SkeletonList from '../../../features/skeleton-list/SkeletonList.tsx';

interface ModelingSectionProps {
  isLoading: boolean;
  error: boolean;
  user: UserInfos | undefined;
}

const ModelingSection: React.FC<ModelingSectionProps> = ({ isLoading, error, user }) => {
  const { t } = useTranslation(['profile']);
  const [isOpenEditModelingParametersOpen, setIsOpenEditModelingParametersOpen] =
    useState<boolean>(false);

  const modelingParams = user?.modelingParametersSet;

  const ModelingParametersKeyDropdown = [
    'proximalDistance',
    'occlusalDistance',
    'retentionZoneWidth',
    'retentionGap',
    'occlusalCementGap',
    'axialCementGap'
  ];

  return (
    <>
      <section className={styles['profile-page__content__section']}>
        <header className={styles['profile-page__content__section__header']}>
          <Text
            data-cy="modeling-title"
            className={styles['profile-page__content__section__header__title']}
            label={t('detailDentist.modelingPrams')}
            size="s"
            type="title"
          />
          {user && (
            <IconButton
              data-cy="edit-modeling-btn"
              iconSize="sm"
              faIconClass="fa-pen-to-square"
              onClick={() => setIsOpenEditModelingParametersOpen(true)}
              radius="full"
            />
          )}
        </header>

        <div className={styles['profile-page__content__section__modeling']}>
          {isLoading && <SkeletonList heightItem="30px" widthItem="300px" count={3} />}
          {user &&
            Object.entries(modelingParams ?? {})
              .filter(([key]) => ModelingParametersKeyDropdown.includes(key))
              .map(([key, value]) => (
                <div key={key} className={styles['profile-page__content__section__modeling__item']}>
                  <Text color={ColorPropsEnum.GREY} label={t(`modalModelingParam.${key}`)} />
                  {!error && (
                    <Text label={`${String(value)} ${t('units.millimeter', { ns: 'common' })}`} />
                  )}
                </div>
              ))}
          <div className={styles['profile-page__content__section__modeling__item']}>
            {modelingParams && !error && (
              <>
                <Text color={ColorPropsEnum.GREY} label={t('modalModelingParam.designStep')} />
                <Text
                  label={t(`modalModelingParam.designStepOptions.${modelingParams.designStep}`)}
                />
              </>
            )}
          </div>
        </div>
      </section>

      {/* Divider */}
      <section className={styles['profile-page__content__divider']}>
        <Divider color={ColorPropsEnum.GREY_300} />
      </section>

      {/*  Edit modeling param modal*/}
      {user && (
        <EditModelingParamModal
          user={user}
          isOpened={isOpenEditModelingParametersOpen}
          onClose={() => setIsOpenEditModelingParametersOpen(false)}
        />
      )}
    </>
  );
};

export default ModelingSection;
