import styles from './edit-customization-modal.module.scss';
import { Button, Modal, RadioList } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../hooks/useForm.tsx';
import { Language } from '../../../../enum/user.ts';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { useEffect } from 'react';
import { ToastType } from '../../../../enum/feedback.ts';
import { UserInfos } from '../../../../models/user.tsx';
import { usePatchUserMutation } from '../../../../services/user.api.services.ts';

type props = {
  isOpened: boolean;
  user: UserInfos;
  onClose: () => void;
};

const EditCustomizationModal = ({ isOpened, user, onClose }: props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();

  const [patchUser, { isSuccess: isPatchedUser, isLoading: isLoadingPatchUser }] =
    usePatchUserMutation();

  const submit = () => {
    const userToUpdate: Partial<UserInfos> = {
      email: user.email,
      language: values.language as Language
    };
    patchUser({ ...userToUpdate });
  };

  useEffect(() => {
    if (isPatchedUser) {
      dispatch(
        feedbackActions.setToast({
          message: t('modalCustomization.toast.customizationUpdated'),
          type: ToastType.SUCCESS
        })
      );
      onClose();
    }
  }, [isPatchedUser]);

  const handleCloseModal = () => {
    onClose();
  };

  const languages = Object.keys(Language).map((value) => {
    return { label: t(`language.${value}`, { ns: 'common' }), value: value };
  });

  const initialValue = {
    language: user.language
  };

  const { values, handleSelect, handleSubmit } = useForm(initialValue, submit);

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleCloseModal}
      title={t('modalCustomization.titleUpdate')}>
      <div className={styles['edit-customization-modal']}>
        <form className={styles['edit-customization-modal__form']} onSubmit={handleSubmit}>
          <RadioList
            title={t('modalCustomization.language')}
            options={languages}
            name="language"
            selectedValue={values.language}
            onClick={(newValue: Language) => handleSelect(newValue, 'language')}
            className={styles['edit-customization-modal__form__radio']}
            data-cy="edit-customization-modal-languages"
          />
          <Button
            label={t('action.update', { ns: 'common' })}
            type="submit"
            isLoading={isLoadingPatchUser}
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            className={styles['edit-customization-modal__form__submit']}
            data-cy="edit-customization-modal-submit"
          />
        </form>
      </div>
    </Modal>
  );
};

export default EditCustomizationModal;
