import { useState } from 'react';
import {
  useUploadToStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../services/orders-api.services';
import { OrderFile } from '../models/order';
import { useDownloadFileFromStorageMutation } from '../services/files-api.services';
import { useAppDispatch } from './hooks';
import { ordersActions } from '../store/orders/orders.reducer';
import { feedbackActions } from '../store/feedback/feedback.reducer';
import { ToastType } from '../enum/feedback';
import { getMessageError } from '../utils/utils';
import { encodeUrl } from '../utils/file.utils';
import {
  ManufacturingFileTypeEnum,
  ManufacturingOrder,
  ManufacturingOrderFile,
  ManufacturingOrderFiles
} from '../models/manufacturing-order.tsx';
import { isComponentTooth } from '../utils/orders.utils.ts';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { t } from 'i18next';
import { ErrorCode } from '../enum/error.ts';

export const useFiles = () => {
  const [uploadToStorage] = useUploadToStorageMutation();
  const [isUploadFilesToStorageSuccess, setIsUploadFilesToStorageSuccess] =
    useState<boolean>(false);
  const [isUploadFilesToStorageError, setIsUploadFilesToStorageError] = useState<boolean>(false);
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const dispatch = useAppDispatch();

  const setLoaderFile = (file: OrderFile, isLoading: boolean) => {
    dispatch(ordersActions.setOneFile({ ...file, isLoading }));
  };

  const displayErrorToast = (error: unknown) => {
    dispatch(
      feedbackActions.setToast({
        message: getMessageError(error),
        type: ToastType.DANGER
      })
    );
  };

  const uploadFilesToStorage = async (orderFiles: OrderFile[]): Promise<void> => {
    try {
      dispatch(ordersActions.setLoadingFiles(true));
      await Promise.all(
        orderFiles.map(async (newFileToUpload) => {
          setLoaderFile(newFileToUpload, true);
          await uploadToStorage({
            url: newFileToUpload.uploadUrl!,
            file: newFileToUpload.data!
          }).unwrap();
          setLoaderFile(newFileToUpload, false);
        })
      );
      setIsUploadFilesToStorageSuccess(true);
    } catch (error) {
      setIsUploadFilesToStorageError(true);
      displayErrorToast(error);
    } finally {
      dispatch(ordersActions.setLoadingFiles(false));
    }
  };

  const loadOrderFilesData = async (
    orderNumber: string,
    filesToDownload: OrderFile[]
  ): Promise<void> => {
    try {
      if (filesToDownload.length === 0) return;
      dispatch(ordersActions.setLoadingFiles(true));
      await Promise.all(
        filesToDownload.map(async (fileToDownload) => {
          try {
            setLoaderFile(fileToDownload, true);
            // 1 - Get download file link
            const downloadableFile = await getOneDownloadableFile({
              orderNumber,
              fileId: fileToDownload.id!
            }).unwrap();

            if (downloadableFile?.link) {
              dispatch(ordersActions.setOneFile({ ...downloadableFile, isLoading: true }));
              // 2 - Download file from storage and save it in store
              const blobFile = await downloadFromStorage({
                url: encodeUrl(downloadableFile.link)
              }).unwrap();
              dispatch(
                ordersActions.setOneFile({
                  ...downloadableFile,
                  data: blobFile
                    ? new File([blobFile], downloadableFile.fileName)
                    : downloadableFile.data,
                  fileLabel: downloadableFile.fileLabel,
                  isLoading: false
                })
              );
            }
          } catch (error) {
            setLoaderFile(fileToDownload, false);
            displayErrorToast(error);
          }
        })
      );
    } finally {
      dispatch(ordersActions.setLoadingFiles(false));
    }
  };

  const addFileToZip = async (zip: JSZip, file: ManufacturingOrderFile) => {
    try {
      const blob = await downloadFromStorage({ url: file.downloadLink });
      if (blob?.data) {
        zip.file(`${file.fileName}.${file.extension}`, blob.data as Blob);
      }
    } catch (error) {
      console.error(`Failed to download file: ${file.fileName}`, error);
    }
  };

  const getComponentProductionFiles = (
    orderFiles: ManufacturingOrderFiles,
    productId: number,
    componentId: number
  ): ManufacturingOrderFile[] => {
    return (
      orderFiles.productFiles
        ?.find((product) => product.id === productId)
        ?.componentFiles?.find((component) => component.id === componentId)
        ?.uploadedManufacturingFiles?.filter((file) =>
          [ManufacturingFileTypeEnum.MESH, ManufacturingFileTypeEnum.MARGIN_LINE].includes(
            file.type
          )
        ) ?? []
    );
  };

  /**
   * Asynchronously zips and downloads the production files associated with a manufacturing order.
   *
   * This function gathers various production-related files from the manufacturing order,
   * including JSON CAM files, mesh files, and margin line files, and packages them into a zip archive
   * using the JSZip library. Once packaged, the archive is downloaded to the client's machine with a
   * pre-defined naming convention.
   *
   * The function retrieves file data by downloading from a specified storage service and ensures
   * files are organized appropriately within the archive. If the necessary manufacturing order
   * or file data is not provided, the function exits early without performing any operations.
   *
   * @param {ManufacturingOrder} manufacturingOrder - The manufacturing order containing product and component details.
   * @param {ManufacturingOrderFiles} orderFiles - The associated files for the manufacturing order, including uploaded files.
   * @returns {Promise<void>} A promise that resolves when the zip archive has been successfully created and downloaded.
   */
  const zipProductionFiles = async (
    manufacturingOrder: ManufacturingOrder,
    orderFiles: ManufacturingOrderFiles
  ): Promise<void> => {
    if (!orderFiles || !manufacturingOrder) return;
    const manufacturingProduct = manufacturingOrder.products.find((product) =>
      product.components.some((component) => isComponentTooth(component))
    );
    if (!manufacturingProduct?.components?.length) return;

    const zip = new JSZip();
    const jsonCamFiles = orderFiles.uploadedManufacturingFiles.filter(
      (file) => file.type === ManufacturingFileTypeEnum.JSON_CAM_FILE
    );
    await Promise.all(jsonCamFiles.map((file) => addFileToZip(zip, file)));
    for (const manufacturingComponent of manufacturingProduct.components) {
      const productionFiles = getComponentProductionFiles(
        orderFiles,
        manufacturingProduct.id,
        manufacturingComponent.id
      );
      await Promise.all(productionFiles.map((file) => addFileToZip(zip, file)));
    }
    try {
      const content = await zip.generateAsync({ type: 'blob' }); // Creates a Blob object
      const jsonFileName = jsonCamFiles?.at(0)?.fileName;
      // Trigger the download using FileSaver
      FileSaver.saveAs(content, `CircleOne_${jsonFileName}.zip`);
    } catch (error) {
      dispatch(
        feedbackActions.setToast({
          message: t(ErrorCode.PRODUCTION_FILES_ZIP_ERROR, { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
      console.error('Error creating zip archive:', error);
    }
  };

  return {
    loadOrderFilesData,
    uploadFilesToStorage,
    isUploadFilesToStorageSuccess,
    isUploadFilesToStorageError,
    zipProductionFiles
  };
};
