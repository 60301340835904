import { api } from './api';
import { Establishment } from '../models/establishment';
import { ManufacturingConfigsCreation } from '../models/manufacturing-config';

export const establishmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createClinic: builder.mutation<Establishment, Establishment>({
      query: (payload: Establishment) => ({
        url: '/establishments',
        method: 'POST',
        body: payload
      })
    }),
    updateEstablishment: builder.mutation<Establishment, Establishment>({
      query: ({ id, ...patch }: Establishment) => ({
        url: `/establishments/${id}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['UserInfo']
    }),
    createManufacturingConfig: builder.mutation<Establishment, ManufacturingConfigsCreation>({
      query: ({ establishmentId, ...payload }: ManufacturingConfigsCreation) => ({
        url: `/establishments/${establishmentId}/manufacturing-configs`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['UserInfo']
    }),
    deleteManufacturingConfig: builder.mutation<
      Establishment,
      {
        establishmentId: number;
        manufacturingConfigId: number;
      }
    >({
      query: ({
        establishmentId,
        manufacturingConfigId
      }: {
        establishmentId: number;
        manufacturingConfigId: number;
      }) => ({
        url: `/establishments/${establishmentId}/manufacturing-configs/${manufacturingConfigId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['UserInfo']
    })
  })
});

export const {
  useCreateClinicMutation,
  useUpdateEstablishmentMutation,
  useCreateManufacturingConfigMutation,
  useDeleteManufacturingConfigMutation
} = establishmentsApi;
