import { Button, Text } from '@platform-storybook/circlestorybook';
import { MachineItemDatagrid } from '../../../models/machine';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { mapManufacturingConfigsDatagrid } from '../../../utils/machine.utils';
import DatagridFeature from '../../../features/datagrid/DatagridFeature';
import { useDatagridColumns } from '../../../hooks/useDatagridColumns';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import styles from './profile-page.module.scss';
import AddEditMachineModal from './add-edit-machine-modal/AddEditMachineModal';
import { UserInfos } from '../../../models/user';
import SkeletonList from '../../../features/skeleton-list/SkeletonList';
import {
  computeDatagridHeight,
  getDefaultExpandedNodes
} from '../../../features/datagrid/datagrid.utils';
import { MaterialBrand } from '../../../models/manufacturing-config';
import DeleteConfigMachineModal from './delete-config-machine-modal/DeleteConfigMachineModal';

interface Props {
  isLoading: boolean;
  user: UserInfos | undefined;
}

const MachineSection = ({ user, isLoading }: Props) => {
  const { t } = useTranslation(['profile']);
  const [isOpenAddMachineOpen, setIsOpenAddMachineOpen] = useState<boolean>(false);
  const [isDeleteMachineModalOpen, setIsDeleteMachineModalOpen] = useState<boolean>(false);
  const [selectedManufacturingConfigId, setSelectedManufacturingConfigId] = useState<
    number | undefined
  >(undefined);
  const [selectedMachineName, setSelectedMachineName] = useState<string | undefined>(undefined);

  const manufacturingConfigs = user?.clinic?.manufacturingConfigs;
  const allBrandReferencesConfigs = (): MaterialBrand[] => {
    const allBrandReferencesConfigs: MaterialBrand[] = [];
    manufacturingConfigs?.forEach((manufacturingConfig) =>
      allBrandReferencesConfigs.push(...manufacturingConfig.materialBrands)
    );
    return allBrandReferencesConfigs;
  };

  const onDeleteAction = (manufacturingConfigId: number, machineName: string) => {
    setIsDeleteMachineModalOpen(true);
    setSelectedManufacturingConfigId(manufacturingConfigId);
    setSelectedMachineName(machineName);
  };

  const {
    colMachineName,
    colMinimalCrownThickness,
    colReinforcementThickness,
    colBrandReference,
    colToolDiameter,
    colActions
  } = useDatagridColumns();

  const machineMaterials: MachineItemDatagrid[] = mapManufacturingConfigsDatagrid(
    manufacturingConfigs ?? []
  );

  const handleClose = () => {
    setIsOpenAddMachineOpen(false);
  };

  const handleCloseDeleteModale = () => {
    setIsDeleteMachineModalOpen(false);
    setSelectedManufacturingConfigId(undefined);
  };

  return (
    <>
      {/*  Add Machine modal */}
      {user?.clinic?.id && isOpenAddMachineOpen && (
        <AddEditMachineModal
          isOpened={isOpenAddMachineOpen}
          establishmentId={user.clinic.id}
          onClose={handleClose}
        />
      )}
      {/* Delete config modal */}
      {user?.clinic?.id && selectedManufacturingConfigId && selectedMachineName && (
        <DeleteConfigMachineModal
          isOpened={isDeleteMachineModalOpen}
          machineName={selectedMachineName}
          configId={selectedManufacturingConfigId}
          onClose={handleCloseDeleteModale}
        />
      )}
      <section className={styles['profile-page__content__section']}>
        <header className={styles['profile-page__content__section__header']}>
          <Text
            data-cy="machine-title"
            className={styles['profile-page__content__section__header__title']}
            label={t('detailDentist.myMachines')}
            size="s"
            type="title"
          />
        </header>

        {/* Conditional machines render */}

        {isLoading && <SkeletonList heightItem="30px" widthItem="300px" count={3} />}
        {manufacturingConfigs && manufacturingConfigs.length > 0 && (
          <>
            <DatagridFeature
              key="machines"
              dataSource={machineMaterials}
              idProperty="id"
              treeColumn="machineName"
              style={computeDatagridHeight(
                machineMaterials.length + allBrandReferencesConfigs().length
              )}
              defaultExpandedNodes={getDefaultExpandedNodes(machineMaterials)}
              nodesProperty="materialBrands"
              columns={[
                colMachineName,
                colBrandReference,
                colToolDiameter,
                colReinforcementThickness,
                colMinimalCrownThickness,
                colActions(onDeleteAction)
              ]}
            />
          </>
        )}
        {user && manufacturingConfigs && manufacturingConfigs.length === 0 && (
          <div className={styles['profile-page__content__section__empty']}>
            <Text label={t('detailDentist.noMachinesAvailable')} color={ColorPropsEnum.GREY} />
          </div>
        )}

        {/* add machine button always visible when the clinic already loaded*/}
        {user?.clinic?.id && (
          <div>
            <Button
              className={styles['profile-page__content__section__add-machine-btn']}
              data-cy="add-machine"
              label={t('detailDentist.addMachineButton')}
              iconLeft="fa-plus"
              onClick={() => setIsOpenAddMachineOpen(true)}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default MachineSection;
