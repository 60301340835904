import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from './hooks/hooks.tsx';
import { isAuthenticatedSelector } from './store/auth/auth.selectors.tsx';
import PublicApp from './pages/public/PublicApp.tsx';
import ErrorPage from './pages/error-page/ErrorPage.tsx';
import ProtectedRoute from './auth/ProtectedRoute.tsx';
import LoginPage from './pages/public/login-page/LoginPage.tsx';
import PrivateApp from './pages/private/PrivateApp.tsx';
import NotFoundPage from './pages/not-found-page/NotFoundPage.tsx';
import TreatmentsPage from './pages/private/treatments-page/TreatmentsPage.tsx';
import RegisterPage from './pages/public/register-page/RegisterPage.tsx';
import ProfilePage from './pages/private/profile-page/ProfilePage.tsx';
import ResetPasswordPage from './pages/public/reset-password-page/ResetPasswordPage.tsx';
import HomePage from './pages/private/home-page/HomePage.tsx';
import EditOrderPage from './pages/private/edit-order-page/EditOrderPage.tsx';
import SupportPage from './pages/private/support-page/SupportPage.tsx';

const RoutesDentist = () => {
  // Redux authentication boolean and Firebase state of authentication to improve the security.
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route element={<PublicApp />} errorElement={<ErrorPage />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route path="partners/:partnerCode/login" element={<LoginPage />} />
        <Route path="partners/:partnerCode/register" element={<RegisterPage />} />
        <Route path="partners/:partnerCode/reset-password" element={<ResetPasswordPage />} />
      </Route>
      {/* Private app */}
      <Route
        element={
          <ProtectedRoute redirectPath="/login" isAllowed={isAuthenticated}>
            <PrivateApp displayNavigationDrawer={true} displayHeader={false} />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route index path="home" element={<HomePage />} />
        <Route path="treatments" element={<TreatmentsPage />} />
        <Route path="support" element={<SupportPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute redirectPath="/login" isAllowed={isAuthenticated}>
            <PrivateApp displayNavigationDrawer={false} displayHeader={true} />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route path="/order/:orderNumber/edit" element={<EditOrderPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesDentist;
