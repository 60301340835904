import {
  BubblePicto,
  Link,
  Text,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import styles from './map-tools.module.scss';
import { ColorPropsEnum } from '../../../../../enum/color.enum.ts';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/hooks.tsx';
import { useGetConnectedUserQuery } from '../../../../../services/user.api.services.ts';
import { mapActions } from '../../../../../store/map/map.reducer.tsx';
import { useTranslation } from 'react-i18next';
import { MaterialBrand } from '../../../../../models/manufacturing-config.tsx';

type Props = {
  currentTeethMachineMaterialInfo?: MaterialBrand;
};

const MachineMaterialBubbles = ({ currentTeethMachineMaterialInfo }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['order']);
  const { data: connectedUser } = useGetConnectedUserQuery();

  const machines = connectedUser?.clinic?.manufacturingMachines;
  const materialBrands = (connectedUser?.clinic?.manufacturingConfigs ?? []).flatMap(
    (config) => config.materialBrands ?? []
  );
  const [machineBrandsInfos, setMachineBrandsInfos] = useState<MaterialBrand[]>([]);

  useEffect(() => {
    if (materialBrands && materialBrands?.length > 0) {
      setMachineBrandsInfos(materialBrands);
    }
  }, [machines]);

  const onMachineBrandBubbleClick = (machineBrandInfo: MaterialBrand): void => {
    dispatch(mapActions.setTeethMachineMaterialInfo(machineBrandInfo));
  };

  return machineBrandsInfos.length ? (
    machineBrandsInfos?.map((materialBrand) => (
      <div className={styles['map-tools__button-material']} key={materialBrand.id}>
        <Tooltip>
          <TooltipContent>
            {t(`editOrder.plan.tooltip.minimalCrownThickness`)}
            {materialBrand.minimalCrownThickness}
            <br />
            {t(`editOrder.plan.tooltip.reinforcementThickness`)}
            {materialBrand.reinforcementThickness}
          </TooltipContent>
          <BubblePicto
            isClickable
            isHoverable
            type="text"
            hoverBorderColor={ColorPropsEnum.PRIMARY}
            onClick={() => onMachineBrandBubbleClick(materialBrand)}
            size="large"
            backgroundColor={
              materialBrand.id === currentTeethMachineMaterialInfo?.id
                ? ColorPropsEnum.PURPLE_LIGHT
                : ColorPropsEnum.GREY_100
            }
            color={
              materialBrand.id === currentTeethMachineMaterialInfo?.id
                ? ColorPropsEnum.PRIMARY
                : ColorPropsEnum.WHITE
            }
            mainTitle={materialBrand.name}
            mainTitleSize="m"
            labelInside={materialBrand.materialAcronym}
            data-cy={`material-machine-bubble-${materialBrand.id}`}
          />
        </Tooltip>
      </div>
    ))
  ) : (
    <div className={styles['map-tools--empty']}>
      <Text label={t('editOrder.plan.emptyMaterialList')} color={ColorPropsEnum.GREY} />
      <Link
        color={ColorPropsEnum.PRIMARY}
        label={t('editOrder.plan.goToProfile')}
        href={`/profile`}
        size="s"
      />
    </div>
  );
};

export default MachineMaterialBubbles;
