export enum MachineCamEnum {
  MILLBOX = 'MILLBOX'
}

export enum ManufacturingProcessEnum {
  PRINT = 'PRINT',
  MILLING = 'MILLING'
}

export enum ToolDiameterEnum {
  MIN = 0.3,
  MAX = 2,
  STEP = 0.05,
  DEFAULT = 0.6
}

export enum AxialCementGapEnum {
  MIN = 0,
  MAX = 0.2,
  STEP = 0.01
}

export enum OcclusalCementGapEnum {
  MIN = 0,
  MAX = 0.3,
  STEP = 0.01
}

export enum RetentionGapEnum {
  MIN = 0,
  MAX = 0.1,
  STEP = 0.01
}

export enum RetentionZoneWidthEnum {
  MIN = 0,
  MAX = 1,
  STEP = 0.01
}

export enum OcclusalDistanceEnum {
  MIN = -0.5,
  MAX = 0.5,
  STEP = 0.05
}

export enum ProximalDistanceEnum {
  MIN = -0.5,
  MAX = 0.5,
  STEP = 0.01
}
