import styles from './edit-modeling-param-modal.module.scss';
import { Button, NumberInput, Modal, Dropdown, Divider } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../hooks/useForm.tsx';
import {
  ProximalDistanceEnum,
  OcclusalDistanceEnum,
  RetentionGapEnum,
  OcclusalCementGapEnum,
  RetentionZoneWidthEnum,
  AxialCementGapEnum
} from '../../../../enum/machine.enum.ts';
import { StringObject } from '../../../../models/common.tsx';
import { DesignStepEnum } from '../../../../enum/modelingStep.enum.ts';
import { UserInfos } from '../../../../models/user.tsx';
import { useEffect } from 'react';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { usePatchUserMutation } from '../../../../services/user.api.services.ts';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { toKebabCase } from '../../../../utils/utils.tsx';

type props = {
  user: UserInfos;
  isOpened: boolean;
  onClose: () => void;
};

const EditModelingParamModal = ({ user, isOpened, onClose }: props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();

  const [patchUser, { isSuccess: isPatchedUser, isLoading: isLoadingPatchedUser }] =
    usePatchUserMutation();

  // Render Number Input function
  type NumberInputProps = {
    name: string;
    label: string;
    enumValues: { MIN: number; MAX: number; STEP: number };
    className: string;
  };

  const renderNumberInput = ({ name, label, enumValues, className }: NumberInputProps) => (
    <NumberInput
      label={t(label)}
      className={className}
      id={name}
      name={name}
      value={values[name]}
      unitLabel={t('units.millimeter', { ns: 'common' })}
      min={enumValues.MIN}
      max={enumValues.MAX}
      step={enumValues.STEP}
      helperText={errors[name] && t('modalModelingParam.errors.numberInputEmpty')}
      variant={errors[name] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
      onBlur={handleBlur}
      onChange={handleChange}
      data-cy={`edit-modeling-param-modal-${toKebabCase(name)}`}
    />
  );

  const submit = () => {
    const userToUpdate: Partial<UserInfos> = {
      email: user.email,
      modelingParametersSet: {
        // Here we use the parseFloat function to make the type float for number input.
        // and when we change the value by keyboard it's considered string. To make all entries
        // same type, we put the as string.
        axialCementGap: parseFloat(values.axialCementGap as string),
        occlusalCementGap: parseFloat(values.occlusalCementGap as string),
        retentionGap: parseFloat(values.retentionGap as string),
        retentionZoneWidth: parseFloat(values.retentionZoneWidth as string),
        occlusalDistance: parseFloat(values.occlusalDistance as string),
        proximalDistance: parseFloat(values.proximalDistance as string),
        designStep: values.designStep as DesignStepEnum
      }
    };
    patchUser({ ...userToUpdate });
  };

  useEffect(() => {
    if (isPatchedUser) {
      dispatch(
        feedbackActions.setToast({
          message: t('modalModelingParam.toast.modelingParamsUpdated'),
          type: ToastType.SUCCESS
        })
      );
      onClose();
    }
  }, [isPatchedUser]);

  const handleCloseModal = () => {
    // reset initial values after modal close
    resetValues();
    // call onClose function
    onClose();
  };

  const initialValue = {
    axialCementGap: user.modelingParametersSet?.axialCementGap,
    occlusalCementGap: user.modelingParametersSet?.occlusalCementGap,
    retentionGap: user.modelingParametersSet?.retentionGap,
    retentionZoneWidth: user.modelingParametersSet?.retentionZoneWidth,
    occlusalDistance: user.modelingParametersSet?.occlusalDistance,
    proximalDistance: user.modelingParametersSet?.proximalDistance,
    designStep: user.modelingParametersSet?.designStep
  };

  // design step dropdown data
  const DesignStepDropdownData = [
    Object.values(DesignStepEnum).map((designStep) => {
      return {
        label: t(`modalModelingParam.designStepOptions.${designStep}`),
        value: designStep
      };
    })
  ];

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (!values[key] && values[key] !== 0) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleChange, handleBlur, resetValues, handleSelect } =
    useForm(initialValue, submit, validateAllFieldsRequired);

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleCloseModal}
      title={t('modalModelingParam.titleUpdate')}>
      <form onSubmit={handleSubmit}>
        <div className={styles['edit-modeling-param-modal']}>
          {/*** Modeling parameters ***/}
          <div className={styles['edit-modeling-param-modal__configuration-section']}>
            <div className={styles['edit-modeling-param-modal__configuration-section__row']}>
              {renderNumberInput({
                name: 'axialCementGap',
                label: 'modalModelingParam.axialCementGap',
                enumValues: AxialCementGapEnum,
                className:
                  styles['edit-modeling-param-modal__configuration-section__row__input-number']
              })}
              {renderNumberInput({
                name: 'occlusalCementGap',
                label: 'modalModelingParam.occlusalCementGap',
                enumValues: OcclusalCementGapEnum,
                className:
                  styles['edit-modeling-param-modal__configuration-section__row__input-number']
              })}
            </div>
            <div className={styles['edit-modeling-param-modal__configuration-section__row']}>
              {renderNumberInput({
                name: 'retentionGap',
                label: 'modalModelingParam.retentionGap',
                enumValues: RetentionGapEnum,
                className:
                  styles['edit-modeling-param-modal__configuration-section__row__input-number']
              })}
              {renderNumberInput({
                name: 'retentionZoneWidth',
                label: 'modalModelingParam.retentionZoneWidth',
                enumValues: RetentionZoneWidthEnum,
                className:
                  styles['edit-modeling-param-modal__configuration-section__row__input-number']
              })}
            </div>

            <div className={styles['edit-modeling-param-modal__configuration-section__row']}>
              {renderNumberInput({
                name: 'occlusalDistance',
                label: 'modalModelingParam.occlusalDistance',
                enumValues: OcclusalDistanceEnum,
                className:
                  styles['edit-modeling-param-modal__configuration-section__row__input-number']
              })}
              {renderNumberInput({
                name: 'proximalDistance',
                label: 'modalModelingParam.proximalDistance',
                enumValues: ProximalDistanceEnum,
                className:
                  styles['edit-modeling-param-modal__configuration-section__row__input-number']
              })}
            </div>

            <Dropdown
              label={t('modalModelingParam.designStep')}
              data={DesignStepDropdownData}
              value={values?.designStep}
              radius="full"
              helperText={errors.designStep && t('modalModelingParam.errors.designStep')}
              variant={errors.designStep ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
              placeholder={t('modalModelingParam.placeholder.designStep')}
              onChange={(newValue: string) => handleSelect(newValue, 'designStep')}
              onBlur={handleBlur}
              className={styles['edit-modeling-param-modal__configuration-section__dropdown']}
              data-cy="edit-modeling-param-modal-design-step-dropdown"
            />

            <div className={styles['edit-modeling-param-modal__configuration-section__submit-btn']}>
              <Button
                isLoading={isLoadingPatchedUser}
                label={t('action.update', { ns: 'common' })}
                type="submit"
                variant={ColorPropsEnum.SUCCESS}
                iconLeft="fa-check"
                data-cy={'edit-modeling-param-modal-submit'}
              />
            </div>
          </div>
          <Divider direction="vertical" className={styles['edit-modeling-param-modal__divider']} />

          <div className={styles['edit-modeling-param-modal__image-section']}>
            <img
              className={styles['edit-modeling-param-modal__image-section__image']}
              src="/image/machine-config-schema.svg"
              alt="machine configuration"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditModelingParamModal;
