import { useEffect, useRef, useState } from 'react';
import styles from './stream-feature.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks/hooks';
import { ordersActions } from '../../../../store/orders/orders.reducer';
import { VncViewer, VncViewerState } from '@anatoscope/react-vncviewer';
import { StreamingParams } from '../../../../models/streaming';
import Tips from '../../../../features/tips/Tips';
import CircularProgress from './circular-progress/CircularProgress.tsx';

type props = {
  streamingParams: StreamingParams | undefined;
  onCloseStreaming: () => void;
};

const StreamFeature = ({ streamingParams, onCloseStreaming }: props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();
  const [vncState, setVncState] = useState(VncViewerState.disconnected);
  const vncViewerRef = useRef<React.ElementRef<typeof VncViewer>>(null);
  const [showTips, setShowTips] = useState<boolean>(true);
  const [isVncViewerVisible, setVncViewerVisible] = useState<boolean>(false);

  useEffect(() => {
    if (streamingParams) {
      // when we got the streaming parameters, hide tips after 20 secs
      setTimeout(() => {
        setShowTips(false);
      }, 20000);

      // show vnc viewer 2sec after connection, just long enough for vnc viewer to resize (we hide the glitch loader)
      setTimeout(() => {
        setVncViewerVisible(true);
      }, 2000);
    }
  }, [streamingParams]);

  useEffect(() => {
    if (vncState === VncViewerState.connected) {
      dispatch(ordersActions.setIsStreaming(true));
    }
  }, [vncState]);

  return (
    <>
      {
        <div
          className={[
            styles['stream-feature__wait-screen'],
            isVncViewerVisible && styles['stream-feature__wait-screen--hidden']
          ].join(' ')}>
          <CircularProgress
            max={100}
            stopAt={40}
            durationInSeconds={23}
            helperText={t('editOrder.design.loading')}
          />
        </div>
      }
      {showTips && (
        <div className={styles['stream-feature__tips']}>
          <Tips onCloseTips={() => setShowTips(false)} />
        </div>
      )}
      {streamingParams && (
        <div className={styles['stream-feature__vnc-container']}>
          <VncViewer
            autoConnect={true}
            resizeSession={true}
            className={[
              styles['stream-feature__vnc-container__viewer'],
              isVncViewerVisible && styles['stream-feature__vnc-container__viewer--visible']
            ].join(' ')}
            ref={vncViewerRef}
            url={`wss://${streamingParams?.host}:${streamingParams?.port}/${streamingParams?.path}`}
            password={streamingParams?.password}
            showMenu={false}
            onConnectionStateChange={(state) => setVncState(state)}
            scaleViewport={true}
            blurOnFocusLoss={false}
            onConnectionLost={onCloseStreaming}
          />
        </div>
      )}
    </>
  );
};
export default StreamFeature;
