import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapClickPayload, MapContext, MapReducer, Position } from '../../models/map';
import { DentalArchEnum, ToothShadeEnum } from '../../enum/component';
import { SelectionContextEnum, ToothSelectionEnum } from '../../enum/map.enum';
import { PositionKeyString } from '../../models/position';
import { StumpMode } from '../../enum/product.enum';
import {
  computeForbiddenPositionsSameProductOnArch,
  computeSelectionToothPositions
} from './map.utils';
import { MaterialBrand } from '../../models/manufacturing-config';

const initialPosition: Position = {
  structureSelection: ToothSelectionEnum.UNSELECTABLE,
  teethStructure: undefined,
  bubble: undefined,
  teethShade: undefined, // not defining a default teethShade, stb handles it
  machineMaterialInfo: undefined,
  gingivaShade: undefined,
  shape: undefined,
  frame: undefined,
  missing: false,
  extract: false,
  selection: ToothSelectionEnum.UNSELECTABLE,
  notation: undefined,
  arch: undefined,
  zone_link: undefined,
  productIds: [],
  svgLayers: [],
  stump: undefined,
  familyColor: undefined // for color line/number of the map
};

const initialMap: MapReducer = {
  positions: {
    '18': {
      ...initialPosition,
      notation: '18',
      arch: DentalArchEnum.UPPER
    },
    '17': {
      ...initialPosition,
      notation: '17',
      arch: DentalArchEnum.UPPER
    },
    '16': {
      ...initialPosition,
      notation: '16',
      arch: DentalArchEnum.UPPER
    },
    '15': {
      ...initialPosition,
      notation: '15',
      arch: DentalArchEnum.UPPER
    },
    '14': {
      ...initialPosition,
      notation: '14',
      arch: DentalArchEnum.UPPER
    },
    '13': {
      ...initialPosition,
      notation: '13',
      arch: DentalArchEnum.UPPER
    },
    '12': {
      ...initialPosition,
      notation: '12',
      arch: DentalArchEnum.UPPER
    },
    '11': {
      ...initialPosition,
      notation: '11',
      arch: DentalArchEnum.UPPER
    },
    '21': {
      ...initialPosition,
      notation: '21',
      arch: DentalArchEnum.UPPER
    },
    '22': {
      ...initialPosition,
      notation: '22',
      arch: DentalArchEnum.UPPER
    },
    '23': {
      ...initialPosition,
      notation: '23',
      arch: DentalArchEnum.UPPER
    },
    '24': {
      ...initialPosition,
      notation: '24',
      arch: DentalArchEnum.UPPER
    },
    '25': {
      ...initialPosition,
      notation: '25',
      arch: DentalArchEnum.UPPER
    },
    '26': {
      ...initialPosition,
      notation: '26',
      arch: DentalArchEnum.UPPER
    },
    '27': {
      ...initialPosition,
      notation: '27',
      arch: DentalArchEnum.UPPER
    },
    '28': {
      ...initialPosition,
      notation: '28',
      arch: DentalArchEnum.UPPER
    },
    '48': {
      ...initialPosition,
      notation: '48',
      arch: DentalArchEnum.LOWER
    },
    '47': {
      ...initialPosition,
      notation: '47',
      arch: DentalArchEnum.LOWER
    },
    '46': {
      ...initialPosition,
      notation: '46',
      arch: DentalArchEnum.LOWER
    },
    '45': {
      ...initialPosition,
      notation: '45',
      arch: DentalArchEnum.LOWER
    },
    '44': {
      ...initialPosition,
      notation: '44',
      arch: DentalArchEnum.LOWER
    },
    '43': {
      ...initialPosition,
      notation: '43',
      arch: DentalArchEnum.LOWER
    },
    '42': {
      ...initialPosition,
      notation: '42',
      arch: DentalArchEnum.LOWER
    },
    '41': {
      ...initialPosition,
      notation: '41',
      arch: DentalArchEnum.LOWER
    },
    '31': {
      ...initialPosition,
      notation: '31',
      arch: DentalArchEnum.LOWER
    },
    '32': {
      ...initialPosition,
      notation: '32',
      arch: DentalArchEnum.LOWER
    },
    '33': {
      ...initialPosition,
      notation: '33',
      arch: DentalArchEnum.LOWER
    },
    '34': {
      ...initialPosition,
      notation: '34',
      arch: DentalArchEnum.LOWER
    },
    '35': {
      ...initialPosition,
      notation: '35',
      arch: DentalArchEnum.LOWER
    },
    '36': {
      ...initialPosition,
      notation: '36',
      arch: DentalArchEnum.LOWER
    },
    '37': {
      ...initialPosition,
      notation: '37',
      arch: DentalArchEnum.LOWER
    },
    '38': {
      ...initialPosition,
      notation: '38',
      arch: DentalArchEnum.LOWER
    }
  },
  mapContext: undefined
};

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialMap,
  reducers: {
    setMapContext: (state, action: PayloadAction<Partial<MapContext>>) => {
      state.mapContext = {
        ...state.mapContext,
        ...action.payload
      } as MapContext;
    },
    resetMapContext: (state) => {
      state.mapContext = undefined;
    },
    resetSelectionTeeth: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        state.positions[positionKey].selection = ToothSelectionEnum.UNSELECTABLE;
      });
    },
    initSelectionTooth: (state) => {
      // Call once before the 1st click on the teethMap
      let forbiddenPositions: Array<PositionKeyString> = [];

      // Check de rule allowSameProductOnArch;
      if (!state.mapContext?.productRule.allowSameProductOnArch && state?.mapContext?.productId) {
        const forbiddenPosSamePrdOnArch = computeForbiddenPositionsSameProductOnArch(
          state.positions,
          state.mapContext.productId
        );
        forbiddenPositions = [...forbiddenPositions, ...forbiddenPosSamePrdOnArch];
      }

      // Set 'selected' 'unselectable' 'selectable' on Positions state, depending on forbiddentPositions and productCompatibilities
      if (state.mapContext) {
        state.positions = computeSelectionToothPositions(
          state.mapContext.productId,
          state.positions,
          state.mapContext.productCompatibilities,
          forbiddenPositions
        );
      }
    },
    computeProductSelectionTooth: (state) => {
      // Called after the 1st click on the teethMap
      const forbiddenPositions: Array<PositionKeyString> = [];

      if (state.mapContext) {
        state.positions = computeSelectionToothPositions(
          state.mapContext.productId,
          state.positions,
          state.mapContext.productCompatibilities,
          forbiddenPositions
        );
      }
    },
    selectSinglePosition: (state, action: PayloadAction<MapClickPayload>): void => {
      const isAlreadySelected =
        state.positions[action.payload.notation].selection === ToothSelectionEnum.SELECTED;
      if (!isAlreadySelected) {
        // If i change my mind, i click on another tooth, reset last tooth selected (only one tooth selected for the product)
        Object.entries(state.positions)
          .filter(([, position]) => position.selection === ToothSelectionEnum.SELECTED)
          .forEach(([positionKey]) => {
            if (positionKey !== action.payload.notation) {
              state.positions[positionKey].selection = initialPosition.selection;
              state.positions[positionKey].stump = initialPosition.stump;
              state.positions[positionKey].teethShade = initialPosition.teethShade;
              state.positions[positionKey].machineMaterialInfo =
                initialPosition.machineMaterialInfo;
              state.positions[positionKey].familyColor = initialPosition.familyColor;
              state.positions[positionKey].svgLayers = initialPosition.svgLayers;
              state.positions[positionKey].teethStructure = initialPosition.teethStructure;
              state.positions[positionKey].bubble = initialPosition.bubble;
            }
          });
      }

      state.positions[action.payload.notation] = {
        ...state.positions[action.payload.notation],
        selection: !isAlreadySelected ? ToothSelectionEnum.SELECTED : initialPosition.selection,
        stump: !isAlreadySelected
          ? action.payload.stumpMode === StumpMode.ALWAYS
          : initialPosition.stump,
        svgLayers: !isAlreadySelected ? action.payload.svgLayers : initialPosition.svgLayers,
        teethShade: !isAlreadySelected ? action.payload.teethShade : initialPosition.teethShade,
        machineMaterialInfo: !isAlreadySelected
          ? action.payload.machineMaterialInfo
          : initialPosition.machineMaterialInfo,
        familyColor: !isAlreadySelected ? action.payload.familyColor : initialPosition.familyColor,
        teethStructure: !isAlreadySelected
          ? action.payload.teethStructure
          : initialPosition.teethStructure,
        bubble: !isAlreadySelected ? action.payload.bubble : initialPosition.bubble
      };
      state.mapContext = {
        ...state.mapContext,
        userAction: SelectionContextEnum.SINGLE_TOOTH,
        activeArch: state.mapContext?.activeArch ?? state.positions[action.payload.notation].arch
      } as MapContext;
    },
    removeActiveProduct: (state) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey] = {
            ...initialPosition,
            notation: state.positions[positionKey].notation,
            arch: state.positions[positionKey].arch,
            productIds: state.positions[positionKey].productIds
          };
        }
      });
    },
    setTeethShade: (state, action: PayloadAction<ToothShadeEnum>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].teethShade = action.payload;
        }
      });
    },
    setTeethMachineMaterialInfo: (state, action: PayloadAction<MaterialBrand>) => {
      Object.keys(state.positions).forEach((positionKey) => {
        if (state.positions[positionKey].selection === ToothSelectionEnum.SELECTED) {
          state.positions[positionKey].machineMaterialInfo = action.payload;
        }
      });
    },
    resetMap: (state) => {
      state.positions = initialMap.positions;
    }
  },

  extraReducers: (builder: ActionReducerMapBuilder<MapReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialMap };
    });
  }
});

export const mapActions = mapSlice.actions;
