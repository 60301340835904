import styles from './edit-dentist-info-modal.module.scss';
import { Button, Dropdown, Modal, TextField } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../hooks/useForm.tsx';
import { DentistRole } from '../../../../enum/user.ts';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { useEffect } from 'react';
import { ToastType } from '../../../../enum/feedback.ts';
import { UserInfos } from '../../../../models/user.tsx';
import { usePatchUserMutation } from '../../../../services/user.api.services.ts';

type props = {
  isOpened: boolean;
  user: UserInfos;
  onClose: () => void;
};

const EditDentistInfoModal = ({ isOpened, user, onClose }: props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();

  const [patchUser, { isSuccess: isPatchedUser, isLoading: isLoadingPatchUser }] =
    usePatchUserMutation();

  const submit = () => {
    const userToUpdate: Partial<UserInfos> = {
      email: user.email,
      phoneNumber: values.phoneNumber as string,
      role: values.role as DentistRole
    };
    patchUser({ ...userToUpdate });
  };

  useEffect(() => {
    if (isPatchedUser) {
      dispatch(
        feedbackActions.setToast({
          message: t('modalInfo.toast.dentistInfoUpdated'),
          type: ToastType.SUCCESS
        })
      );
      onClose();
    }
  }, [isPatchedUser]);

  const handleCloseModal = () => {
    onClose();
  };

  const rolesForDropdown = [
    [
      {
        label: t(`user.roles.${DentistRole.ASSISTANT.toLowerCase()}`, { ns: 'common' }),
        value: DentistRole.ASSISTANT
      },
      {
        label: t(`user.roles.${DentistRole.DENTIST.toLowerCase()}`, { ns: 'common' }),
        value: DentistRole.DENTIST
      }
    ]
  ];

  const initialValue = {
    email: user.email,
    phoneNumber: user.phoneNumber,
    role: user.role
  };

  const { values, errors, handleChange, handleBlur, handleSelect, handleSubmit } = useForm(
    initialValue,
    submit
  );

  return (
    <Modal isOpened={isOpened} onClose={handleCloseModal} title={t('modalInfo.titleUpdate')}>
      <div className={styles['edit-dentist-info-modal']}>
        <form className={styles['edit-dentist-info-modal__form']} onSubmit={handleSubmit}>
          <TextField
            label={t('modalInfo.email')}
            isDisabled
            id="email"
            name="email"
            value={values.email}
            type="email"
            helperText={errors.email && t('modalInfo.errors.email')}
            className={styles['edit-dentist-info-modal__form__textfield']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.email ? 'danger' : 'default'}
            placeholder={t('modalInfo.placeholder.email')}
            radius="full"
            data-cy="edit-dentist-info-modal-email"
          />
          <TextField
            label={t('modalInfo.phoneNumber')}
            id="phoneNumber"
            name="phoneNumber"
            value={values.phoneNumber}
            type={'tel'}
            helperText={errors.phoneNumber && t('modalInfo.errors.phoneNumber')}
            className={styles['edit-dentist-info-modal__form__textfield']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.phoneNumber ? 'danger' : 'default'}
            placeholder={t('modalInfo.placeholder.phoneNumber')}
            radius="full"
            data-cy="edit-dentist-info-modal-phoneNumber"
          />
          <Dropdown
            label={t('modalInfo.role')}
            isDisabled
            data={rolesForDropdown}
            value={values?.role}
            radius="full"
            helperText={errors.role && t('modalInfo.errors.role')}
            variant={errors.role ? 'danger' : 'default'}
            placeholder={t('modalInfo.placeholder.role')}
            onChange={(newValue: string) => handleSelect(newValue, 'role')}
            onBlur={handleBlur}
            className={styles['edit-dentist-info-modal__form__dropdown']}
            data-cy="edit-dentist-info-modal-roles-dropdown"
          />
          <Button
            label={t('action.update', { ns: 'common' })}
            type="submit"
            isLoading={isLoadingPatchUser}
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            className={styles['edit-dentist-info-modal__form__submit']}
            data-cy="edit-dentist-info-modal-submit"
          />
        </form>
      </div>
    </Modal>
  );
};

export default EditDentistInfoModal;
