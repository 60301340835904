import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { useEffect, useState } from 'react';
import { WorkflowStepEnum } from '../../../../enum/workflow-step.ts';
import { history } from '../../../../models/history.tsx';
import { useNavigate } from 'react-router-dom';
import styles from './export.module.scss';
import { Box, Button, Text, Viewer } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { OrderFile } from '../../../../models/order.tsx';
import { mapFileToOrderFile } from '../../../../utils/file.utils.ts';
import { ColorPropsEnum } from '../../../../enum/color.enum.ts';
import { useDownloadFileFromStorageMutation } from '../../../../services/files-api.services.ts';
import { ordersActions } from '../../../../store/orders/orders.reducer.tsx';
import { mapActions } from '../../../../store/map/map.reducer.tsx';
import { isComponentTooth } from '../../../../utils/orders.utils.ts';
import {
  useGetOneManufacturingOrderQuery,
  useLazyGetComponentProductionFilesQuery,
  useLazyGetProductionFilesQuery
} from '../../../../services/manufacturing-orders-api.services.tsx';
import {
  ManufacturingFileTypeEnum,
  ManufacturingOrderFile
} from '../../../../models/manufacturing-order.tsx';
import { useGetOneOrderQuery } from '../../../../services/orders-api.services.ts';
import { useFiles } from '../../../../hooks/useFiles.tsx';

type Props = {
  orderNumber: string;
};
const ExportStep = ({ orderNumber }: Props) => {
  const { data: orderSelect } = useGetOneOrderQuery(orderNumber);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  history.navigate = useNavigate();
  const { t } = useTranslation(['order']);

  const [file3dToDisplay, setFile3dToDisplay] = useState<OrderFile>();
  const [fileData, setFileData] = useState<BlobPart | null>(null);
  const [skipManufacturingOrderQuery, setSkipManufacturingOrderQuery] = useState<boolean>(false);

  const [downloadFromStorage, { isError: isErrorDownloadFromStorage }] =
    useDownloadFileFromStorageMutation();
  const [getComponentProductionFiles, { isError: isErrorDownloadingComponentProductionFiles }] =
    useLazyGetComponentProductionFilesQuery();
  const [getProductionFiles] = useLazyGetProductionFilesQuery();
  // we query order => until we reach validation step => then allow query on manufacturing order and skip order
  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(orderNumber, {
    pollingInterval: 1000,
    skip: skipManufacturingOrderQuery
  });

  useEffect(() => {
    if (manufacturingOrder?.currentStep === WorkflowStepEnum.DELIVERED) {
      setSkipManufacturingOrderQuery(true);
      handleLoadInViewer();
    }
  }, [manufacturingOrder]);

  const { zipProductionFiles } = useFiles();

  const retrieveProductionFiles = async () => {
    if (!manufacturingOrder) return;
    const product = manufacturingOrder.products.filter((product) =>
      product.components.some((component) => isComponentTooth(component))
    )[0];
    const component = product.components.filter((component) => isComponentTooth(component))[0];
    const { data: productionFiles } = await getComponentProductionFiles({
      productId: product.id,
      componentId: component.id
    });

    return productionFiles;
  };

  const getDownloadedFile = async (file: ManufacturingOrderFile) => {
    const { data: downloadedFile } = await downloadFromStorage({
      url: file.downloadLink
    });

    return downloadedFile;
  };

  const handleLoadInViewer = async () => {
    if (!manufacturingOrder) return;
    const uploadedProductionFiles = await retrieveProductionFiles();
    const uploadedMeshProductionFiles = uploadedProductionFiles?.uploadedManufacturingFiles.filter(
      (file) => file.type === ManufacturingFileTypeEnum.MESH
    );
    if (!uploadedMeshProductionFiles?.length) return;
    const uploadedMeshFiles = uploadedMeshProductionFiles[0];
    const downloadedFile = await getDownloadedFile(uploadedMeshFiles);
    if (downloadedFile) {
      setFileData(downloadedFile);
      loadProductionFilesInViewer(uploadedMeshFiles, downloadedFile as Blob);
    }
  };

  // handle download when click on button download
  const handleDownload = async () => {
    if (!manufacturingOrder) return;

    const { data: productionFiles } = await getProductionFiles({
      orderNumber: manufacturingOrder.orderNumber,
      patientReference: orderSelect!.patient.reference!
    });
    await zipProductionFiles(manufacturingOrder, productionFiles!);
    navigate('/treatments'); // Redirect after download
    // reset map and order
    dispatch(ordersActions.resetOrder());
    dispatch(mapActions.resetMap());
  };

  const loadProductionFilesInViewer = (productionFile: ManufacturingOrderFile, fileData: Blob) => {
    const file: File = new File(
      [fileData],
      `${productionFile.fileName}.${productionFile.extension}`
    );
    setFile3dToDisplay(mapFileToOrderFile(file, false));
  };

  const isDownloading = (): boolean =>
    (!fileData && !isErrorDownloadFromStorage && !isErrorDownloadingComponentProductionFiles) ||
    !skipManufacturingOrderQuery;

  return (
    <div className={styles['export']}>
      <Text
        label={t('editOrder.design.export.description')}
        color={ColorPropsEnum.GREY}
        className={styles['export__text-file']}
      />
      <Box color={ColorPropsEnum.GREY} className={styles['export__viewer']} padding="spacing-0">
        <Viewer
          noFileLabel={t('editOrder.patientFiles.noFile')}
          file3D={file3dToDisplay}
          isLoading={isDownloading()}
          className={styles['export__viewer__content']}
        />

        <div className={styles['export__title']}>
          <Button
            label={t(`editOrder.design.export.${isDownloading() ? 'title' : 'action'}`)}
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            data-cy={'download-files-button'}
            isDisabled={fileData === null}
            onClick={() => handleDownload()}
            isLoading={isDownloading()}
          />
        </div>
      </Box>
    </div>
  );
};

export default ExportStep;
