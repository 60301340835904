import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { OrderFile, OrderForCreation } from '../../../../models/order';
import { mapFileToOrderFile, checkUploadingFile } from '../../../../utils/file.utils';
import { Text, FileUpload } from '@platform-storybook/circlestorybook';
import styles from './files-section.module.scss';
import {
  useCreateOrderMutation,
  useCreatePatientFileMutation
} from '../../../../services/orders-api.services';
import { useAppDispatch } from '../../../../hooks/hooks';
import { getDefaultPatientReference } from '../../../../utils/orders.utils';
import { useNavigate } from 'react-router';
import { useGetConnectedUserQuery } from '../../../../services/user.api.services';
import { useFiles } from '../../../../hooks/useFiles';
import { Tag } from '../../../../enum/order';
import LoadingModal from '../../../../features/loading-modal/LoadingModal';
import i18next from 'i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { getMessageError } from '../../../../utils/utils';
import { ToastType } from '../../../../enum/feedback';

const FilesSection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);
  const [isCreatingOrder, setIsCreatingOrder] = useState<boolean>(false);
  const [patientFiles, setPatientFiles] = useState<OrderFile[]>([]);
  const [createFilesResult, setCreateFilesResults] = useState<OrderFile[]>([]);
  const [isCreatePatientFilesSuccess, setIsCreatePatientFilesSuccess] = useState<boolean>(false);
  const { data: connectedUser } = useGetConnectedUserQuery();
  const [
    createOrder,
    { data: createdOrder, isSuccess: isSuccessCreateOrder, isError: isErrorCreateOrder }
  ] = useCreateOrderMutation();
  const [createPatientFile] = useCreatePatientFileMutation();

  const { isUploadFilesToStorageSuccess, isUploadFilesToStorageError, uploadFilesToStorage } =
    useFiles();

  const onUploadFiles = async (newFilesToUpload: File[]): Promise<void> => {
    setIsCreatingOrder(true);
    const newFilesMap: OrderFile[] = [];
    // map patient files and add them to state
    for (const newFileToUpload of newFilesToUpload) {
      const orderFile = mapFileToOrderFile(newFileToUpload);
      newFilesMap.push(orderFile);
      if (!checkUploadingFile(orderFile, patientFiles, dispatch)) {
        setIsCreatingOrder(false);
        return;
      }
    }
    setPatientFiles(newFilesMap);
  };

  const createFiles = async (orderNumber: string) => {
    if (patientFiles?.length) {
      const promises = [];
      for (const newFileToUpload of patientFiles) {
        promises.push(
          createPatientFile({
            orderNumber,
            file: {
              fileName: newFileToUpload.fileName,
              extension: newFileToUpload.extension,
              mimeType: newFileToUpload.mimeType
            }
          })
        );
      }
      Promise.all([...promises])
        .then((values) => {
          const newFiles = patientFiles.map((file, index) => {
            return { ...file, ...values[index].data };
          });
          setCreateFilesResults(newFiles);
          setIsCreatePatientFilesSuccess(true);
        })
        .catch((error) => {
          setIsCreatingOrder(false);
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    }
  };

  const saveOrder = async (): Promise<void> => {
    const orderToCreate: OrderForCreation = {
      dentistName: `${connectedUser?.firstName} ${connectedUser?.lastName}`,
      dentistEmail: `${connectedUser?.email}`,
      clinicName: `${connectedUser?.clinic?.name}`,
      clinicId: +`${connectedUser?.clinic?.id}`,
      toManufacture: true,
      patient: { reference: getDefaultPatientReference() },
      tags: [Tag.CHAIRSIDE],
      labId: +`${connectedUser?.laboratory?.id}`,
      labName: `${connectedUser?.laboratory?.name}`,
      expectedDate: new Date(),
      instructions: ''
    };
    await createOrder(orderToCreate);
  };

  // 1 - Files are uploaded -> Create order
  useEffect(() => {
    if (patientFiles.length > 0) {
      saveOrder();
    }
  }, [patientFiles]);

  // 2 - Create files in the created order
  useEffect(() => {
    if (isSuccessCreateOrder) {
      createFiles(createdOrder.orderNumber);
    }
  }, [isSuccessCreateOrder]);

  // 3 - Upload files in storage
  useEffect(() => {
    if (isCreatePatientFilesSuccess) {
      uploadFilesToStorage(createFilesResult);
    }
  }, [isCreatePatientFilesSuccess]);

  // 4 - Redirect to order edit page
  useEffect(() => {
    if (isSuccessCreateOrder && isUploadFilesToStorageSuccess) {
      navigate(`/order/${createdOrder.orderNumber}/edit`);
    }
  }, [isSuccessCreateOrder, isUploadFilesToStorageSuccess]);

  useEffect(() => {
    if (isUploadFilesToStorageError || isErrorCreateOrder) {
      setIsCreatingOrder(false);
    }
  }, [isUploadFilesToStorageError, isErrorCreateOrder]);

  return (
    <>
      <LoadingModal isOpened={isCreatingOrder} />
      <div className={styles['files-section']}>
        <div className={styles['files-section__title']}>
          <Text
            size="s"
            type="title"
            color="white"
            bold
            textAlign="center-align"
            label={t('files.title').toUpperCase()}
            className={styles['files-section__action__title']}
          />
        </div>
        <div className={styles['files-section__upload']}>
          <FileUpload
            data-cy="home-file-upload"
            title={t('files.fileUpload.title')}
            subtitle={t('files.fileUpload.subtitle')}
            onUploadFile={onUploadFiles}
            className={styles['files-section__action__upload__file-upload']}
            language={i18next.language}
            mode="dark"
            withButton
            buttonLabel={t('files.fileUpload.buttonlabel')}
            buttonVariant={ColorPropsEnum.TERTIARY}
          />
        </div>
      </div>
    </>
  );
};

export default FilesSection;
