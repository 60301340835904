import moment from 'moment';
import { ColorPropsEnum } from '../enum/color.enum';
import { Order, OrderForList, OrderItemComponent } from '../models/order';
import { Component } from '../models/component.tsx';
import { ManufacturingOrderComponent } from '../models/manufacturing-order.tsx';
import { ComponentType } from '../enum/component.ts';

export const isOrderChairsideCompliant = (order: Order | OrderForList): boolean | undefined => {
  // validation to one design if all products in the order are chairside compliant
  return order?.items?.length === order?.items?.filter((item) => item.product.isChairSide)?.length;
};

export const getBackgroundColor = (order: OrderForList) => {
  return isOrderChairsideCompliant(order) ? ColorPropsEnum.GREY_100 : ColorPropsEnum.PURPLE_MEDIUM;
};

export const getDefaultPatientReference = () => {
  return 'REF_' + moment().format('YYMMDDHHmm');
};

export const isComponentTooth = (
  component: OrderItemComponent | Component | ManufacturingOrderComponent
): boolean => [ComponentType.TOOTH, ComponentType.PARTIAL_TOOTH].includes(component.componentType);
