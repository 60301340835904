import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyGetOrdersQuery } from '../../../services/orders-api.services';
import { Button, Text, Search } from '@platform-storybook/circlestorybook';
import OrderCard from './order-card/OrderCard';
import styles from './treatments-page.module.scss';
import SkeletonOrdersList from './skeleton-orders-llist/SkeletonOrdersList';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../enum/color.enum';
import CreateTreatmentModal from '../../../features/create-treatment-modal/CreateTreatmentModal';
import { OrderForList } from '../../../models/order';

const TreatmentsPage = () => {
  const [page, setPage] = useState<number>(1);
  const [getOrders, { isLoading }] = useLazyGetOrdersQuery();
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [ordersList, setOrdersList] = useState<OrderForList[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false);
  const [isTreatmentTypeModalOpened, setIsTreatmentTypeModalOpened] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const { t } = useTranslation(['treatments']);

  useEffect(() => {
    if (totalPages && page > totalPages) {
      return;
    }
    fetchOrders();
  }, [page, searchValue]);

  const LIMIT = 16;
  const skeletonNumber = Math.round(LIMIT / 2);

  // Debounce: Wait the user to stop typing
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const fetchOrders = async () => {
    try {
      setIsOrderLoading(true);
      const { data: result, meta } = await getOrders({
        page: page,
        limit: LIMIT,
        filtersQuery: '&filter.currentStep=$in:delivered',
        searchQuery: searchValue
      }).unwrap();

      setOrdersList((prevOrders) => {
        // we check if we are in page === 1 or the prev result === current result we reinitialize the order list state
        if (JSON.stringify(prevOrders) === JSON.stringify(result) || page === 1) {
          // Because in dev with React.StrictMode, the component is built twice
          return [...result];
        }
        return [...prevOrders, ...result];
      });

      // set totalPages once
      if (meta.currentPage === 1) {
        setTotalPages(meta.totalPages);
      }
      setIsOrderLoading(false);
    } catch {
      setIsOrderLoading(false);
      setError(true);
    }
  };

  const deleteOrderCard = (orderNumber: string) => {
    const newOrderList = ordersList.filter((order) => order.orderNumber !== orderNumber);
    setOrdersList(newOrderList);
  };

  // For infinite scrolling
  const lastOrderCardElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;
      // To avoid observing multiple times
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // the observed element is in the viewport, 100% visible
            setPage((prevPage) => {
              return prevPage + 1;
            });
          }
        },
        { threshold: 0.5 }
      );

      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  // Function handle on change search input with a delay of 300ms
  const handleChangeSearch = (searchInput: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    setSearchInputValue(searchInput);
    // Delay to change the search value
    debounceTimeout.current = setTimeout(() => {
      setSearchValue(searchInput);
      setPage(1); // reinitialize the page to 1 on a new search.
    }, 400); // delay of 400ms
  };

  return (
    <section className={styles['treatments-page']}>
      <CreateTreatmentModal
        isOpened={isTreatmentTypeModalOpened}
        onClose={() => setIsTreatmentTypeModalOpened(false)}
      />
      <header className={styles['treatments-page__header']}>
        <Text
          size="xs"
          type="title"
          label={t('title')}
          data-cy="treatments-title"
          color={ColorPropsEnum.GREY}
        />
      </header>
      <div className={styles['treatments-page__actions']}>
        <Search
          data-cy="treatments-search"
          placeholder={t('search.placeholder')}
          onChange={(searchInput: string) => handleChangeSearch(searchInput)}
          value={searchInputValue}
        />
        <Button
          data-cy="treatments-fab"
          label={t('button.label.fab')}
          variant={ColorPropsEnum.TERTIARY}
          iconLeft="fa-plus"
          onClick={() => {
            setIsTreatmentTypeModalOpened(true);
          }}
        />
      </div>
      {ordersList.length === 0 && !isLoading && searchInputValue && (
        <Text
          data-cy="treatments-not-found"
          label={t('search.resultNotFound')}
          color={ColorPropsEnum.GREY}
        />
      )}
      {ordersList.length === 0 && !isOrderLoading && !searchInputValue && (
        <div className={styles['treatments-page--empty']}>
          <Text
            data-cy="treatments-not-available"
            label={t('noTreatment.title')}
            bold
            color={ColorPropsEnum.GREY}
          />
          <Text
            data-cy="treatments-not-available"
            label={t('noTreatment.description')}
            color={ColorPropsEnum.GREY}
          />
          <Button
            data-cy="treatments-empty-list-button"
            category="outlined"
            label={t('button.label.fab')}
            onClick={() => {
              setIsTreatmentTypeModalOpened(true);
            }}
          />
        </div>
      )}
      <div className={styles['treatments-page__cards']}>
        {isOrderLoading && !searchInputValue && <SkeletonOrdersList count={skeletonNumber} />}
        {ordersList.length > 0 &&
          !isLoading &&
          ordersList?.map((order, index) => (
            <OrderCard
              handleDeleteOrderCard={deleteOrderCard}
              key={order.id}
              order={order}
              // Attach lastOrderCardElementRef to the last card
              ref={
                index === ordersList.length - 1 && totalPages && totalPages > 1
                  ? lastOrderCardElementRef
                  : null
              }
            />
          ))}
        {totalPages > 0 && !error && page < totalPages && (
          <SkeletonOrdersList count={skeletonNumber} />
        )}
      </div>
    </section>
  );
};
export default TreatmentsPage;
