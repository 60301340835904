import styles from './delete-config-machine-modal.module.scss';
import { Button, Modal, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
// import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { useDeleteManufacturingConfigMutation } from '../../../../services/establishments-api.services.ts';
import { useGetConnectedUserQuery } from '../../../../services/user.api.services.ts';
import { useEffect } from 'react';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';

type props = {
  isOpened: boolean;
  configId: number;
  machineName: string;
  onClose: () => void;
};

const DeleteConfigMachineModal = ({ isOpened, configId, machineName, onClose }: props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();
  const [
    deleteDeleteManufacturingConfig,
    { isLoading: isLoadingDeleteManufacturingConfig, isSuccess: isSuccessDeleteManufacturingConfig }
  ] = useDeleteManufacturingConfigMutation();
  const { data: user } = useGetConnectedUserQuery();

  const handleCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    if (isSuccessDeleteManufacturingConfig) {
      dispatch(
        feedbackActions.setToast({
          message: t('modalMachine.toast.machineDeleted', { machineName }),
          type: ToastType.SUCCESS
        })
      );
      onClose();
    }
  }, [isSuccessDeleteManufacturingConfig]);

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleCloseModal}
      title={t('modalDeleteConfigMachine.title', { machineName })}>
      <div className={styles['delete-config-machine-modal']}>
        <Text
          textAlign="center-align"
          label={t('modalDeleteConfigMachine.text')}
          color={ColorPropsEnum.GREY}
        />
        <Button
          data-cy="delete-order-modal-button"
          label={t('action.delete', { ns: 'common' })}
          variant={ColorPropsEnum.ALERT}
          iconLeft="fa-trash"
          className={styles['delete-config-machine-modal__button']}
          isLoading={isLoadingDeleteManufacturingConfig}
          onClick={async () => {
            if (user?.clinic.id) {
              await deleteDeleteManufacturingConfig({
                establishmentId: user.clinic.id,
                manufacturingConfigId: configId
              });
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default DeleteConfigMachineModal;
