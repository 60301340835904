import { Skeleton } from '@platform-storybook/circlestorybook';
import styles from './skeleton-list.module.scss';
import { ColorPropsEnum } from '../../enum/color.enum';
interface SkeletonListProps {
  count: number;
  heightItem: string;
  widthItem: string;
  className?: string;
}

const SkeletonList = ({ count, heightItem, widthItem, className }: SkeletonListProps) => {
  const skeleton = [];
  for (let i = 0; i < count; i++) {
    skeleton.push(
      <Skeleton
        backgroundColor={ColorPropsEnum.GREY_100}
        key={i}
        height={heightItem}
        width={widthItem}
        className={className ? className : styles['skeleton-list']}></Skeleton>
    );
  }
  return <>{skeleton}</>;
};

export default SkeletonList;
