import { api } from './api';
import {
  ComponentFiles,
  ManufacturingOrder,
  ManufacturingOrderFiles
} from '../models/manufacturing-order';

const prefix_path = '/manufacturing/';

export const manufacturingOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneManufacturingOrder: builder.query<ManufacturingOrder, string>({
      query: (orderNumber) => {
        return `${prefix_path}orders/${orderNumber}`;
      }
    }),
    deleteManufacturingOrder: builder.mutation<void, { orderNumber: string }>({
      query: ({ orderNumber }) => ({
        url: `/manufacturing/orders/${orderNumber}`,
        method: 'DELETE'
      })
    }),
    getProductionFiles: builder.query<
      ManufacturingOrderFiles,
      { orderNumber: string; patientReference: string }
    >({
      query: (params) => ({
        url: `/manufacturing/orders/${params.orderNumber}/production-files?patientRef=${params.patientReference}`
      })
    }),
    getComponentProductionFiles: builder.query<
      ComponentFiles,
      {
        productId: number;
        componentId: number;
      }
    >({
      query: (params) => ({
        url: `/manufacturing/products/${params.productId}/components/${params.componentId}/files`
      })
    }),
    endTask: builder.mutation<void, string>({
      query: (orderNumber) => ({
        url: `${prefix_path}orders/${orderNumber}/modeling/endTask`,
        method: 'POST'
      })
    })
  })
});

export const {
  useGetOneManufacturingOrderQuery,
  useLazyGetOneManufacturingOrderQuery,
  useDeleteManufacturingOrderMutation,
  useLazyGetProductionFilesQuery,
  useLazyGetComponentProductionFilesQuery,
  useEndTaskMutation
} = manufacturingOrdersApi;
