import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { TypeColWithNamePropertyPlatform } from '../../models/datagrid';

export const getColumnOptions = (
  name: string,
  header: string,
  defaultFlex?: number,
  sortable?: boolean // true by default
): Partial<TypeColWithNameProperty> => {
  let column: Partial<TypeColWithNamePropertyPlatform> = {
    name: name,
    headerAlign: 'start',
    header: header,
    showColumnMenuTool: true,
    showColumnMenuToolOnHover: false,
    enableColumnFilterContextMenu: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left'
      }
    })
  };
  if (defaultFlex) {
    column = { ...column, defaultFlex: defaultFlex };
  }
  if (sortable !== undefined) {
    column = { ...column, sortable: sortable };
  }
  return column;
};

// Get object of all ids row to expand them all
export const getDefaultExpandedNodes = <T extends { id: number }>(data: T[]) =>
  data.reduce(
    (acc, row) => {
      acc[row.id] = true;
      return acc;
    },
    {} as Record<number, boolean>
  );

export const computeDatagridHeight = (dataLength?: number): { minHeight: number } | undefined => {
  if (dataLength) {
    const headerHeight = RowHeight;
    // We add half a row to make it obvious it's scrollable
    const maxDatagridHeight = headerHeight + RowHeight * 10.5;
    let computedHeight = headerHeight + dataLength * RowHeight;
    computedHeight = computedHeight < maxDatagridHeight ? computedHeight : maxDatagridHeight;
    return computedHeight ? { minHeight: computedHeight } : undefined;
  }
  return { minHeight: 150 };
};

export const RowHeight = 46;
