import styles from './add-edit-machine-modal.module.scss';
import { Button, Dropdown, Modal, NumberInput } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../hooks/useForm.tsx';
import { ToolDiameterEnum } from '../../../../enum/machine.enum.ts';
import { ComponentType } from '../../../../enum/component.ts';
import { useGetCommonTypesQuery } from '../../../../services/common-types-api.services.ts';
import { useEffect, useMemo } from 'react';
import { MaterialBrandCreation } from '../../../../models/manufacturing-config.tsx';
import { CheckboxItem } from '../../../../models/form.tsx';
import { useCreateManufacturingConfigMutation } from '../../../../services/establishments-api.services.ts';
import { useGetMachinesQuery } from '../../../../services/machines-api.services.ts';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';

type props = {
  isOpened: boolean;
  establishmentId: number;
  onClose: () => void;
};

const AddEditMachineModal = ({ isOpened, establishmentId, onClose }: props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();

  const {
    data: commonTypes,
    isLoading: areCommonTypesLoading,
    isSuccess: isSuccessGetCommonTypes
  } = useGetCommonTypesQuery();

  const allMaterials = commonTypes?.materials.filter(
    (material) => material.componentType === ComponentType.TOOTH && material.brandReferences?.length
  );

  const [
    createManufacturingConfig,
    { isLoading: isLoadingCreateManufacturingConfig, isSuccess: isSuccessCreateManufacturingConfig }
  ] = useCreateManufacturingConfigMutation();

  const {
    data: machinesData,
    isLoading: isLoadingGetMachines,
    isSuccess: isSuccessGetMachines
  } = useGetMachinesQuery();

  useEffect(() => {
    if (isSuccessCreateManufacturingConfig) {
      const machineName = machinesData?.find((machine) => machine.id === values.machineId)?.name;
      dispatch(
        feedbackActions.setToast({
          message: t('modalMachine.toast.machineCreated', { machineName }),
          type: ToastType.SUCCESS
        })
      );
      handleCloseModal();
    }
  }, [isSuccessCreateManufacturingConfig]);

  const materialsDropdown = useMemo(() => {
    if (!isSuccessGetCommonTypes || !allMaterials?.length) return [];
    const materialsForDropdown: Array<CheckboxItem> = allMaterials.map((material) => {
      return {
        label: t(`material.${material.code}`, { ns: 'component' }),
        value: material.id
      };
    });
    return [materialsForDropdown];
  }, [isSuccessGetCommonTypes]);

  const machinesDropdown = useMemo(() => {
    if (!isSuccessGetMachines) return [];
    return [
      machinesData.map((machine) => {
        return {
          label: `${machine.name} (${t(`modalMachine.type.${machine.manufacturingProcess.toLowerCase()}`)})`,
          value: machine.id
        };
      })
    ];
  }, [isSuccessGetMachines]);

  const getMaterialReferencesToSave = (): MaterialBrandCreation[] | undefined => {
    if (values.material && (values.brands as CheckboxItem[]).length > 0) {
      const brandsSelected = (values.brands as CheckboxItem[])
        .flat()
        .filter((brand: CheckboxItem) => brand.isChecked)
        .map((brand: CheckboxItem) => brand.value);

      return allMaterials
        ?.filter((material) => values.material === material.id!)
        .flatMap((material) => {
          return material
            .brandReferences!.filter((brand) => brandsSelected.includes(brand.id!))
            .map((brand) => {
              return {
                acronym: brand.acronym!,
                name: brand.label!,
                materialCode: material.code,
                materialAcronym: material.acronym,
                reinforcementThickness: brand.reinforcementThickness!,
                minimalCrownThickness: brand.minimalCrownThickness!
              };
            });
        });
    }
    return [];
  };

  // Submit to create machine
  const submit = async () => {
    const materialBrands = getMaterialReferencesToSave();
    await createManufacturingConfig({
      establishmentId: establishmentId,
      toolDiameter: values.toolDiameter as number,
      machineId: values.machineId as number,
      materialBrands: materialBrands ?? []
    });
  };

  const handleCloseModal = () => {
    resetValues();
    onClose();
  };

  const handleMaterialSelect = (newValue: number) => {
    const material = allMaterials!.find((material) => material.id === newValue);

    const brandsForDropdown = material?.brandReferences?.map((brand) => {
      return {
        label: brand.label!,
        value: brand.id
      };
    });
    setValues({
      ...values,
      brands: brandsForDropdown ? [brandsForDropdown] : []
    });
    handleSelect(newValue || '', 'material');
  };

  const initialValue = {
    machineId: undefined,
    material: undefined,
    brands: [],
    toolDiameter: ToolDiameterEnum.DEFAULT
  };

  const {
    values,
    errors,
    handleSubmit,
    handleSelect,
    handleChange,
    handleBlur,
    handleMultiSelect,
    resetValues,
    setValues
  } = useForm(initialValue, submit);

  return (
    <Modal
      isOpened={isOpened}
      onClose={handleCloseModal}
      title={t('modalMachine.titleCreate')}
      className={styles['add-edit-machine-modal']}>
      <form
        className={styles['add-edit-machine-modal__form']}
        onSubmit={handleSubmit}
        data-cy="modal-machine">
        <Dropdown
          label={t('modalMachine.machine.label')}
          data={machinesDropdown}
          value={values?.machineId}
          radius="full"
          data-cy="machine-dropdown"
          isLoading={isLoadingGetMachines}
          helperText={errors.machineId ? t('modalMachine.machine.error') : ''}
          variant={errors.machineId ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('modalMachine.machine.placeholder')}
          onChange={(newValue: string) => {
            handleSelect(newValue, 'machineId');
          }}
          onBlur={handleBlur}
        />
        <NumberInput
          label={t('modalMachine.toolDiameter.label')}
          id={'toolDiameter'}
          name={'toolDiameter'}
          value={values['toolDiameter']}
          unitLabel={t('units.millimeter', { ns: 'common' })}
          min={ToolDiameterEnum.MIN}
          max={ToolDiameterEnum.MAX}
          step={ToolDiameterEnum.STEP}
          helperText={errors['toolDiameter'] && t('modalMachine.toolDiameter.error')}
          variant={errors['toolDiameter'] ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          onBlur={handleBlur}
          onChange={handleChange}
          data-cy={'tool-diameter'}
        />
        <Dropdown
          label={t('modalMachine.material.label')}
          name="materials"
          data={materialsDropdown}
          value={values?.material}
          isLoading={areCommonTypesLoading}
          radius="full"
          helperText={errors.material ? t('modalMachine.material.error') : ''}
          variant={errors.material ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('modalMachine.material.placeholder')}
          onChange={(newValue: number) => handleMaterialSelect(+newValue)}
          onBlur={handleBlur}
          data-cy="material-dropdown"
        />
        <Dropdown
          label={t('modalMachine.brand.label')}
          isSearchable={true}
          multichoice={true}
          name="brands"
          data={values?.brands}
          isLoading={areCommonTypesLoading}
          radius="full"
          helperText={
            errors.brands ? t('modalMachine.brand.error') : t('modalMachine.brand.helperText')
          }
          variant={errors.brands ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          placeholder={t('modalMachine.brand.placeholder')}
          onChange={(newValue: string) => {
            handleMultiSelect(newValue, 'brands');
          }}
          onBlur={handleBlur}
          className={styles['add-edit-machine-modal__form__material-section__box__dropdown']}
          data-cy="brands-dropdown"
        />
        <Button
          label={t('action.create', { ns: 'common' })}
          type="submit"
          variant={ColorPropsEnum.SUCCESS}
          isLoading={isLoadingCreateManufacturingConfig}
          iconLeft="fa-check"
          className={styles['add-edit-machine-modal__form__submit']}
          data-cy={'add-edit-machine-modal-submit'}
        />
      </form>
    </Modal>
  );
};

export default AddEditMachineModal;
