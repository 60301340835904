import { WorkflowManufacturingStepEnum, WorkflowStepEnum } from '../enum/workflow-step';
import { CircleCadVersion } from './circle-cad-version';
import { ProductSubCategoryEnum } from '../enum/product.subcategory.enum';
import { ComponentType, ToothStructureEnum } from '../enum/component';

export interface ManufacturingOrder {
  id: number;
  orderNumber: string;
  assigneeName?: string;
  assigneeEmail?: string;
  controlled?: boolean;
  tags: string[];
  dentistEmail: string;
  dentistName: string;
  patientId?: number;
  instructions?: string;
  creationDate: Date;
  circleCadVersion?: CircleCadVersion;
  toManufacture?: boolean;
  isUrgent?: boolean;
  manufacturingDate?: Date;
  products: ManufacturingOrderProduct[];
  currentStep: WorkflowStepEnum;
  files: string[];
  deletedAt?: string;
  streamingParameters?: string;
  labId: number;
  labName: string;
  families: string[];
}

export interface ManufacturingOrderProduct {
  id: number;
  labelFr: string;
  labelEn: string;
  family: string;
  class?: string;
  category?: string;
  subCategory?: ProductSubCategoryEnum;
  stumpPositions?: number[];
  injectionPositions?: number[];
  components: ManufacturingOrderComponent[];
  isCompliantCircleCad?: boolean;
  isOneDesign?: boolean;
  isChairside?: boolean;
  deletedAt?: string;
}

export interface ManufacturingOrderPaginatedResponse {
  data: ManufacturingOrder[];
  links: object;
  meta: {
    currentPage: number;
    filter: object;
    itemsPerPage: number;
    sortBy: Array<Array<string>>;
    totalItems: number;
    totalPages: number;
  };
}

export interface ManufacturingOrderComponentStructure {
  structure: ToothStructureEnum;
  positions: number[];
}

export interface ManufacturingOrderComponent {
  id: number;
  componentType: ComponentType;
  teethPositions?: number[];
  material?: string;
  shade?: string;
  brandReference?: string;
  shape?: string;
  structures?: ManufacturingOrderComponentStructure[];
  angulation?: string;
  aspect?: string;
  orderNumber?: string;
  toManufacture?: boolean;
  isUrgent?: boolean;
  productId?: number;
  family?: string;
  manufacturingStep: WorkflowManufacturingStepEnum;
  commentExists: boolean;
  isInError: boolean;
}

export interface ManufacturingOrderFiles {
  orderNumber: string;
  uploadedManufacturingFiles: ManufacturingOrderFile[];
  productFiles: ProductFiles[];
}

export interface ProductFiles {
  id: number;
  uploadedManufacturingFiles: ManufacturingOrderFile[];
  componentFiles: ComponentFiles[];
}

export interface ComponentFiles {
  id: number;
  uploadedManufacturingFiles: ManufacturingOrderFile[];
}

export interface ManufacturingOrderFile {
  type: ManufacturingFileTypeEnum;
  tag: string;
  fileName: string;
  extension: string;
  mimeType: string;
  downloadLink: string;
}

export enum ManufacturingFileTypeEnum {
  MESH = 'MESH',
  MESH_METADATA = 'MESH_METADATA',
  MARGIN_LINE = 'MARGIN_LINE',
  DESIGN_TRACABILITY_INFO = 'DESIGN_TRACABILITY_INFO',
  JSON_CAM_FILE = 'JSON_CAM_FILE'
}
