import React, { useState } from 'react';
import styles from './profile-page.module.scss'; // Assure-toi que le fichier CSS existe
import { IconButton, Skeleton, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { UserInfos } from '../../../models/user.tsx';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import { Language } from '../../../enum/user.ts';
import EditCustomizationModal from './edit-customization-modal/EditCustomizationModal.tsx';

interface Props {
  error: boolean;
  isLoading: boolean;
  user?: UserInfos;
}

const CustomizationSection: React.FC<Props> = ({ error, isLoading, user }) => {
  const { t } = useTranslation(['profile']);

  const [isOpenEditCustomizationModal, setIsOpenEditCustomizationModal] = useState<boolean>(false);

  return (
    <section className={styles['profile-page__content__section']}>
      <header className={styles['profile-page__content__section__header']}>
        <Text
          className={styles['profile-page__content__section__header__title']}
          label={t('detailDentist.customization')}
          size="s"
          type="title"
        />
        {user && (
          <IconButton
            data-cy="edit-customization-btn"
            iconSize="sm"
            faIconClass="fa-pen-to-square"
            onClick={() => setIsOpenEditCustomizationModal(true)}
            radius="full"
          />
        )}
      </header>
      <div className={styles['profile-page__content__section__main__item']}>
        <Text color={ColorPropsEnum.GREY} label={t('detailDentist.language')}></Text>
        {isLoading && (
          <Skeleton height="30px" width="300px" backgroundColor={ColorPropsEnum.GREY_100} />
        )}
        {!isLoading && !error && (
          <Text
            label={
              user?.language && Object.values(Language).includes(user.language)
                ? t(`language.${user?.language}`, { ns: 'common' })
                : t('detailDentist.unknown')
            }></Text>
        )}
      </div>

      {/*  Edit dentist customization*/}
      {user && (
        <EditCustomizationModal
          isOpened={isOpenEditCustomizationModal}
          user={user}
          onClose={() => setIsOpenEditCustomizationModal(false)}
        />
      )}
    </section>
  );
};

export default CustomizationSection;
