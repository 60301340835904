import { useTranslation } from 'react-i18next';
import styles from './home-page.module.scss';
import { Button, Text, Skeleton } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from '../../../moment-config';
import { useGetConnectedUserQuery } from '../../../services/user.api.services';
import { Language } from '../../../enum/user';
import FilesSection from './files-section/FilesSection';
import CreateTreatmentModal from '../../../features/create-treatment-modal/CreateTreatmentModal';
import TreatmentsSection from './treatments-section/TreatmentsSection';
import {
  publicDocumentsUrl,
  publicImagesUrl,
  publicOneNewsUrlEn,
  publicOneNewsUrlFr
} from '../../../utils/utils.tsx';

const HomePage = () => {
  const { t } = useTranslation(['home']);
  const [isCreateTreatmentModalOpened, setIsCreateTreatmentModalOpened] = useState<boolean>(false);

  const { data: connectedUser, isLoading: isUserLoading } = useGetConnectedUserQuery();

  if (connectedUser) {
    // Here we set the locale value for moment so date can be displayed in the correct language
    // We do it here since we couldn't make it work directly in PrivateApp.tsx
    const currentLanguage = connectedUser?.language === Language.fr_FR ? 'fr' : 'en';
    moment.locale(currentLanguage);
  }

  const eventRedirectionUrl =
    connectedUser?.language === Language.fr_FR ? publicOneNewsUrlFr : publicOneNewsUrlEn;

  return (
    <section className={styles['home-page']}>
      <CreateTreatmentModal
        isOpened={isCreateTreatmentModalOpened}
        onClose={() => setIsCreateTreatmentModalOpened(false)}
      />
      <header className={styles['home-page__header']}>
        {isUserLoading ? (
          <Skeleton type="text" backgroundColor={ColorPropsEnum.GREY_100} />
        ) : (
          <Text
            size="xs"
            type="title"
            label={t('title').toUpperCase()}
            data-cy="home-title"
            color={ColorPropsEnum.GREY}
          />
        )}
      </header>
      <div className={styles['home-page__container']}>
        <div className={styles['home-page__container__header']}>
          <div className={styles['home-page__container__header__title']}>
            {isUserLoading ? (
              <>
                <Skeleton
                  type="title"
                  height="48px"
                  width="300px"
                  backgroundColor={ColorPropsEnum.GREY_100}
                />
                <Skeleton
                  type="text"
                  height="16px"
                  width="100px"
                  backgroundColor={ColorPropsEnum.GREY_100}
                />
              </>
            ) : (
              <>
                <Text
                  size="m"
                  type="title"
                  label={t('welcome') + connectedUser?.lastName}
                  data-cy="home-dentist"
                  className={styles['home-page__container__header__title__dentist']}
                  color={ColorPropsEnum.PRIMARY}
                />
                <Text
                  size="xs"
                  type="title"
                  label={moment().format('dddd, DD MMMM YYYY')}
                  data-cy="home-date"
                  color={ColorPropsEnum.GREY}
                />
              </>
            )}
          </div>
          {isUserLoading ? (
            <Skeleton
              type="square"
              width="150px"
              height="40px"
              backgroundColor={ColorPropsEnum.GREY_100}
            />
          ) : (
            <Button
              data-cy="home-createTreatment"
              label={t('button.label.fab')}
              variant={ColorPropsEnum.TERTIARY}
              iconLeft="fa-plus"
              onClick={() => {
                setIsCreateTreatmentModalOpened(true);
              }}
            />
          )}
        </div>
        <div className={styles['home-page__container__content']}>
          <div className={styles['home-page__container__content__links']}>
            {isUserLoading ? (
              <>
                <Skeleton
                  type="square"
                  width="100%"
                  height="45%"
                  backgroundColor={ColorPropsEnum.GREY_100}
                />
                <Skeleton
                  type="square"
                  width="100%"
                  height="15%"
                  backgroundColor={ColorPropsEnum.GREY_100}
                />
              </>
            ) : (
              <>
                <FilesSection />
                <div
                  className={styles['home-page__container__content__links__event']}
                  data-cy="home-event">
                  <Text
                    size="s"
                    label={t('event.title').toUpperCase()}
                    data-cy="home-event-title"
                    className={styles['home-page__container__content__links__event__title']}
                  />
                  <div className={styles['home-page__container__content__links__event__content']}>
                    <Text
                      size="s"
                      label={t('event.description')}
                      data-cy="home-event-description"
                      color={ColorPropsEnum.WHITE}
                    />
                    <a
                      href={eventRedirectionUrl}
                      target={'_blank'}
                      className={
                        styles['home-page__container__content__links__event__content__more']
                      }
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} size="sm" />
                      <Text
                        size="s"
                        label={t('event.more')}
                        data-cy="home-event-more"
                        color={ColorPropsEnum.WHITE}
                      />
                    </a>
                  </div>
                </div>
              </>
            )}

            <div className={styles['home-page__container__content__links__media']}>
              {isUserLoading ? (
                <>
                  <Skeleton
                    type="text"
                    width="50%"
                    height="100%"
                    backgroundColor={ColorPropsEnum.GREY_100}
                  />
                  <Skeleton
                    type="text"
                    width="50%"
                    height="100%"
                    backgroundColor={ColorPropsEnum.GREY_100}
                  />
                </>
              ) : (
                <>
                  <div
                    className={styles['home-page__container__content__links__media__video']}
                    data-cy="home-one">
                    <video
                      controls
                      poster={`${publicImagesUrl}home/DiscoverCircleOne.jpg`}
                      className={styles['home-page__container__content__links__media__player']}>
                      <source
                        src={`${publicDocumentsUrl}video/Home_Video_Crown.mp4`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div
                    className={styles['home-page__container__content__links__media__video']}
                    data-cy="home-tip">
                    {connectedUser && (
                      <video
                        controls
                        poster={
                          connectedUser.language === Language.fr_FR
                            ? `${publicImagesUrl}home/Astuces-InsertionLimite-Dark.jpg`
                            : `${publicImagesUrl}home/Tips-MarginLine-Dark.jpg`
                        }
                        className={styles['home-page__container__content__links__media__player']}>
                        <source
                          src={`${publicDocumentsUrl}video/One-Home-Tips.mp4`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {isUserLoading ? (
            <>
              <Skeleton
                type="text"
                width="40%"
                height="100%"
                backgroundColor={ColorPropsEnum.GREY_100}
              />
            </>
          ) : (
            <TreatmentsSection
              onCreateTreatmentButton={() => setIsCreateTreatmentModalOpened(true)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default HomePage;
