import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../enum/color.enum';
import styles from './support-page.module.scss';
import { Text, Link } from '@platform-storybook/circlestorybook';
import { publicManualUrl } from '../../../utils/utils';

const SupportPage = () => {
  const { t } = useTranslation(['support']);

  return (
    <div className={styles['support-page']}>
      <header className={styles['support-page__header']}>
        <Text
          size="xs"
          type="title"
          label={t('title')}
          data-cy="support-title"
          color={ColorPropsEnum.GREY}
        />
      </header>
      <section className={styles['support-page__manual']}>
        <Text
          className={styles['support-page__manual__title']}
          size="s"
          type="title"
          label={t('manual.title')}
          data-cy="support-manual-title"
        />
        <div className={styles['support-page__manual__content']}>
          <Text label={t('manual.text')} />
          <Link
            data-cy="support-manual-link"
            href={publicManualUrl}
            label={t('manual.link')}
            target="_blank"
            type="external"
          />
        </div>
      </section>
    </div>
  );
};

export default SupportPage;
