import React, { useState } from 'react';
import styles from './profile-page.module.scss'; // Assure-toi que le fichier CSS existe
import { IconButton, Skeleton, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { UserInfos } from '../../../models/user.tsx';
import EditDentistInfoModal from './edit-dentist-info-modal/EditDentistInfoModal.tsx';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';

interface Props {
  error: boolean;
  isLoading: boolean;
  user: UserInfos | undefined;
}

const InfoSection: React.FC<Props> = ({ error, user, isLoading }) => {
  const { t } = useTranslation(['profile']);
  const [isOpenEditDentistInfoModal, setIsOpenEditDentistInfoModal] = useState<boolean>(false);
  return (
    <>
      <section className={styles['profile-page__content__section']}>
        <header className={styles['profile-page__content__section__header__name']}>
          <div className={styles['profile-page__content__section__header__name-with-status']}>
            {user && (
              <>
                <Text
                  data-cy="dentist-name"
                  className={styles['profile-page__content__section__header__title']}
                  label={`${user.firstName} ${user.lastName}`}
                  size="s"
                  type="title"></Text>
                <IconButton
                  data-cy="edit-user-info-btn"
                  iconSize="sm"
                  faIconClass="fa-pen-to-square"
                  onClick={() => setIsOpenEditDentistInfoModal(true)}
                  radius="full"
                />
              </>
            )}
            {isLoading && (
              <Skeleton height="30px" width="300px" backgroundColor={ColorPropsEnum.GREY_100} />
            )}
          </div>
        </header>
        <div className={styles['profile-page__content__section__main']}>
          <div>
            <div className={styles['profile-page__content__section__main__item']}>
              <Text
                color={ColorPropsEnum.GREY}
                label={t('detailDentist.identification.email')}></Text>
              {isLoading && (
                <Skeleton height="30px" width="300px" backgroundColor={ColorPropsEnum.GREY_100} />
              )}
              {!isLoading && !error && <Text label={user?.email ?? ''}></Text>}
            </div>
            {!isLoading && user?.phoneNumber && (
              <div className={styles['profile-page__content__section__main__item']}>
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t('detailDentist.identification.phone')}></Text>
                {!error && <Text label={user?.phoneNumber}></Text>}
              </div>
            )}
            <div className={styles['profile-page__content__section__main__item']}>
              <Text
                color={ColorPropsEnum.GREY}
                label={t('detailDentist.identification.role')}></Text>
              {isLoading && (
                <Skeleton height="30px" width="300px" backgroundColor={ColorPropsEnum.GREY_100} />
              )}
              {!isLoading && !error && (
                <Text
                  label={t(`user.roles.${user?.role.toLowerCase()}`, {
                    ns: 'common'
                  })}></Text>
              )}
            </div>
          </div>
        </div>
      </section>
      {/*  Edit dentist info modal*/}
      {user && (
        <EditDentistInfoModal
          isOpened={isOpenEditDentistInfoModal}
          user={user}
          onClose={() => setIsOpenEditDentistInfoModal(false)}
        />
      )}
    </>
  );
};

export default InfoSection;
